export const Table = {
  //multicomponent parts
  parts: ['table', 'td', 'tr'],
  
  //style that all have in common
  baseStyle: {
    table: {
      width: '100%',
      padding: '0'
    },
    td: {
      textAlign: 'center',
      maxWidth: '130px',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    tr: {
      cursor: 'pointer'
    }
  },

  //customize variants
  variants: {
    small: {
      td: {
        fontSize: '9px',
      }
    }
  }
};