export const referralCommissionConstants = {
  GETALL_REQUEST: 'REFERRAL_COMMISSION_GETALL_REQUEST',
  GETALL_SUCCESS: 'REFERRAL_COMMISSION_GETALL_SUCCESS',
  GETALL_FAILURE: 'REFERRAL_COMMISSION_GETALL_FAILURE',

  CREATE_REQUEST: 'REFERRAL_COMMISSION_CREATE_REQUEST',
  CREATE_SUCCESS: 'REFERRAL_COMMISSION_CREATE_SUCCESS',
  CREATE_FAILURE: 'REFERRAL_COMMISSION_CREATE_FAILURE',
  
  UPDATE_REQUEST: 'REFERRAL_COMMISSION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'REFERRAL_COMMISSION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'REFERRAL_COMMISSION_UPDATE_FAILURE',

  DELETE_REQUEST: 'REFERRAL_COMMISSION_DELETE_REQUEST',
  DELETE_SUCCESS: 'REFERRAL_COMMISSION_DELETE_SUCCESS',
  DELETE_FAILURE: 'REFERRAL_COMMISSION_DELETE_FAILURE',

  RESET_REFERRAL_COMMISSION: "RESET_REFERRAL_COMMISSION"
}