import React from 'react';
import { useSelector } from 'react-redux';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    ResponsiveContainer,
    LabelList
} from 'recharts';

const defaults = {
    data: [
        {
            quarter: "Pending",
            fill: "#6b6b6b",
            earnings: 20
        },
        {
            quarter: "Failed",
            fill: "#da0a0a",
            earnings: 10
        },
        {
            quarter: "Completed",
            fill: "#6DC4F2",
            earnings: 30
        },
        {
            quarter: "On Hold",
            fill: "#6DC4F2",
            earnings: 8
        },
        {
            quarter: "Cancelled",
            fill: "#da0a0a",
            earnings: 7
        },
        {
            quarter: "Released",
            fill: "#6DC4F2",
            earnings: 13
        },
        {
            quarter: "Initiated",
            fill: "#6DC4F2",
            earnings: 12
        },
    ],
    margin: {
        top: 5,
        right: 5,
        left: 5,
        bottom: 5,
    },
    xKey: "quarter",
    yKey: "earnings",
    xType: "category",
    yType: "number",
    xFormat: x => x,
    yFormat: y => y,
    yDomain: [ 0, 100 ],
    xDomain: [ 'dataMin', 'dataMax' ],
    labelFormat: val => `${ val }%`

};

const CustomizedLabel = ( props ) => {
    const {
        xOff = 20,
        x = 0,
        y = 0,
        width,
        value,
        format,
        style,
        yOff = 10 } = props;

    return (
        <g>
            <text
                fill={ style.fill }
                x={ x + ( width / 2 ) - xOff }
                y={ y - yOff }>
                { format( value ) }
            </text>
        </g>
    );
};

const MyBarChart = (
    {
        data = defaults.data,
        margin = defaults.margin,
        xKey = defaults.xKey,
        yKey = defaults.yKey,
        xType = defaults.xType,
        yType = defaults.yType,
        xFormat = defaults.xFormat,
        yFormat = defaults.yFormat,
        yDomain = defaults.yDomain,
        xDomain = defaults.xDomain,
        labelPos = "top",
        labelFormat = defaults.labelFormat,
        labelStyles,
        yOffset
    }
) => {

    const { darkMode } = useSelector( state => state.theme )
    
    const styles = { fill: darkMode ? "white" : "#303030", ...labelStyles };
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={ 900 }
                height={ 300 }
                data={ data }
                margin={ margin }>
                <XAxis
                    dataKey={ xKey }
                    domain={ xDomain }
                    tickFormatter={ xFormat }
                    type={ xType }
                />
                <YAxis
                    dataKey={ yKey }
                    domain={ yDomain }
                    tickFormatter={ yFormat }
                    type={ yType }
                />
                <Bar
                    dataKey={ yKey }>
                    <LabelList
                        dataKey={ yKey }
                        position={ labelPos }
                        content={ 
                            <CustomizedLabel
                                xOff={ margin.left }
                                yOff={ yOffset }
                                style={ styles }
                                format={ labelFormat } 
                            /> 
                        }
                    />
                    { data.map( ( entry, index ) => (
                        <Cell key={ `cell-${ index }` }
                            fill={ entry.fill } />
                    ) ) }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export { MyBarChart };
