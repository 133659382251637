export const p2pCommissionConstants = {
  GETALL_REQUEST: 'P2P_COMMISSION_GETALL_REQUEST',
  GETALL_SUCCESS: 'P2P_COMMISSION_GETALL_SUCCESS',
  GETALL_FAILURE: 'P2P_COMMISSION_GETALL_FAILURE',

  CREATE_REQUEST: 'P2P_COMMISSION_CREATE_REQUEST',
  CREATE_SUCCESS: 'P2P_COMMISSION_CREATE_SUCCESS',
  CREATE_FAILURE: 'P2P_COMMISSION_CREATE_FAILURE',
  
  UPDATE_REQUEST: 'P2P_COMMISSION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'P2P_COMMISSION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'P2P_COMMISSION_UPDATE_FAILURE',

  DELETE_REQUEST: 'P2P_COMMISSION_DELETE_REQUEST',
  DELETE_SUCCESS: 'P2P_COMMISSION_DELETE_SUCCESS',
  DELETE_FAILURE: 'P2P_COMMISSION_DELETE_FAILURE',

  RESET_P2P_COMMISSION: "RESET_P2P_COMMISSION"
}