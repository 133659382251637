import { tradePairConstants } from '../constants';

import 
{
    request,
    failure,
    getAllSuccess
} from "./utils";

const initialState =
{
    content: [],
    status: "idle",
    realCount: 0,
    totalPages: 0,
    totalElements: 0,
    first: true,
    last: false,
    number: 0,
    size: 24,
    error: null,

    createStatus: "idle",
    createErr: null,

    delStatus: "idle",
    delErr: null,

    editStatus: "idle",
    editErr: null
};

export function tradePairs ( state = initialState, action )
{
    const { type, payload } = action;

    const content = [ ...state.content ];
    switch ( type ) 
    {
        case tradePairConstants.GETALL_TP_REQUEST:
            return request( state );

        case tradePairConstants.GETALL_TP_FAILURE:
            return failure( state, action.payload );

        case tradePairConstants.GETALL_TP_SUCCESS:
            return getAllSuccess( state, action.payload );

        case tradePairConstants.CREATE_TP_REQUEST:
            return {
                ...state,
                createStatus: "pending",
                createErr: null
            };

        case tradePairConstants.CREATE_TP_SUCCESS:
            return {
                ...state,
                createStatus: "resolved",
                createErr: null,
                content: [ payload, ...content ]
            };

        case tradePairConstants.CREATE_TP_FAILURE:
            return {
                ...state,
                createStatus: "rejected",
                createErr: payload
            };

        case tradePairConstants.EDIT_TP_REQUEST:
            return {
                ...state,
                editStatus: "pending",
                editErr: null
            };


        case tradePairConstants.EDIT_TP_FAILURE:
            return {
                ...state,
                editStatus: "rejected",
                editErr: payload
            };

        case tradePairConstants.EDIT_TP_SUCCESS:
            const index = content.findIndex( t => t.id === payload.id );
            content[ index ] = payload;
            return {
                ...state,
                editStatus: "resolved",
                editErr: null,
                content,
            };


        case tradePairConstants.DELETE_TP_REQUEST:
            return {
                ...state,
                delStatus: "pending",
                delErr: null
            };

        case tradePairConstants.DELETE_TP_FAILURE:
            return {
                ...state,
                delStatus: "rejected",
                delErr: payload
            };


        case tradePairConstants.DELETE_TP_SUCCESS:
            return {
                ...state,
                delStatus: "resolved",
                delErr: null,
                realCount: state.realCount - 1,
                totalElements: state.totalElements - 1,
                content: content.filter( pair => pair.id !== payload.id )
            };

        case tradePairConstants.RESET_TP_EDIT:
            return {
                ...state,
                editStatus: "idle",
                editErr: null
            };

        case tradePairConstants.RESET_TP_DELETE:
            return {
                ...state,
                delStatus: "idle",
                delErr: null
            };

        case tradePairConstants.RESET_TP_CREATE:
            return {
                ...state,
                createStatus: "idle",
                createErr: null
            };

        case tradePairConstants.RESET_TP:
            return initialState;

        default:
            return state;
    }

}
