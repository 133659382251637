import React from 'react';
import classNames from 'classnames';

import styles from './Page.module.scss';
import { useSelector } from 'react-redux';


const Page = ( { pageClass, children } ) =>
{
    const { darkMode } = useSelector (state => state.theme)
    return (
        <div className={ classNames
            ( [styles.page, darkMode ? styles.dark: '',pageClass] ) }>
            { children }
        </div>
    );
};

export { Page };
