import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { authActions } from '../redux/actions';

function PrivateRoute ( { children, ...props } ) 
{
    const { accessToken } = useSelector( state => state.auth.user );

    const dipatch = useDispatch();

    const user = localStorage.getItem( 'user' );

    if ( !accessToken && user )
    {
        const s = JSON.parse( user );
        dipatch( authActions.setUser( s ) );
    }

    return (
        <Route { ...props }>
            {
                ( accessToken || user ) ?
                    children
                    : <Redirect to="/login" />
            }
        </Route>
    );
}

export { PrivateRoute };
