import { Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import styles from './index.module.scss'

export const ViewReferrals = ({data, isOpen, close, username}) => {
  const history = useHistory()
  const USER_PERMISSIONS = JSON.parse(localStorage.getItem('user'))?.payload.permissions || [];

  return(
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader marginBottom={'20px'} fontSize={'18px'} textTransform={'capitalize'} textAlign={'center'}>{username}'s Referrals</ModalHeader>
        <ModalCloseButton />

        <ModalBody className={styles['modal-body']}>
          {
            data.length === 0 ?
            <p style={{marginTop: '100px'}}>This user has no referrals</p>
            :
            <>
              <Divider />
              {
                data.map((item, index) => (
                  <>
                    <p
                      key={'p' + index}
                      onClick={() => {
                        if(USER_PERMISSIONS.includes('ViewUserDetails')){
                          close();
                          history.push(`/dashboard/users/${item.id}`)
                      }
                      }}
                      className={styles['referrals']} 
                      >
                        {item.username}
                    </p>
                    <Divider key={'d' + index}/>
                  </>
                ))
              }
            </>
            
          }
        </ModalBody>

        {
          data.length > 0 &&
          <ModalFooter textAlign={'center'} fontSize={'14px'} color={'gray'} marginTop={'40px'}>
            Click on referral for more details.
          </ModalFooter>
        }
      </ModalContent>
    </Modal>
  )
}