export const sharePairConstants =
{
    GETALL_SP_SUCCESS: "GETALL_SP_SUCCESS",
    GETALL_SP_FAILURE: "GETALL_SP_FAILURE",
    GETALL_SP_REQUEST: "GETALL_SP_REQUEST",

    EDIT_SP_SUCCESS: "EDIT_SP_SUCCESS",
    EDIT_SP_FAILURE: "EDIT_SP_FAILURE",
    EDIT_SP_REQUEST: "EDIT_SP_REQUEST",

    RESET_SP: "RESET_SP",
    RESET_EDIT: "RESET_EDIT"
};
