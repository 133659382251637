import { Box, Button, Collapse, StackDivider, Text, VStack } from "@chakra-ui/react"
import classNames from "classnames"
import { useState } from "react"
import { useEffect } from "react"
import { MdRefresh } from "react-icons/md"
// import { BsFilter } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { currencyActions } from "../../redux/actions"
import { api } from "../../utils/api"
import { formatCurrency } from "../../utils/helpers"
import { Loader } from "../Loader/Loader"
import { Retry } from "../Retry/Retry"
import styles from './index.module.scss'

const mockData = {
  MATIC: '30',
  BSC: '50',
  VC_NGN: '500',
  BTC: '0.0007',
  VC_USD: '15',
  ETH: '0',
}

const sortImages = (currencyData) => {
  let db = {}
  for(let currency of currencyData){
    db[currency.name] = currency.imgUrl
  }
  return db
}

const formatCurrencyName = (currencyName) => {
  if(currencyName.includes('VC_')){
      return currencyName.split('_')[1]
  }
  else{
      return currencyName
  }
}

const CurrencyCard = ({item, currencyImages, data}) => {
  return(
    <Box className={styles['currency-card']}>
      <img 
        className={styles['currency-image']} 
        src={currencyImages[item]} 
        alt=""/>
      <Text className={styles['currency-name']} >{formatCurrencyName(item)}</Text>
      <Text className={styles['currency-amount']}>{ item.includes('VC_') ? formatCurrency(data[item]) : data[item] }</Text>
    </Box>
  )
}

const initialQueryState = {
  filterValue: 'SEND',
  txStatus: 'COMPLETED',
  period: 'ThisMonth',
}

export const TransactionVolume = () => {
  const dispatch = useDispatch()

  useEffect (() => {
    dispatch( currencyActions.getAll())
  }, [ dispatch ])

  const { darkMode } = useSelector( state => state.theme )
  const { content, status } = useSelector( state => state.currencies)
  const currencyImages = sortImages(content)

  const [ data, setData ] = useState(mockData)
  const [ queries, setQueries ] = useState(initialQueryState)
  const [ startDate, setStartDate ] = useState('')
  const [ endDate, setEndDate ] = useState('')

  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)

  const currencyList = Object.keys(data)

  const queryChange = (e) => {
    const { name, value } = e.target
    setQueries( q => ({
      ...queries,
      [name]: value
    }))
  }

  useEffect(() => {
    setQueries(initialQueryState)
    setLoading(true)
    api.get(`transaction/tx/volumes/`, { filterType: 'operationType', filterValue: 'SEND', txStatus: 'COMPLETED', period: 'ThisMonth' }, { useHeaders: true, useQuery: true })
      .then(data => {
        setLoading(false);
        if(data.error){ 
          setError(true)
          console.log(data.error)
        } else {
          setData(data)
        }
      })
  }, [])

  const fetchDefault = () => {
    setQueries(initialQueryState)
    setLoading(true)
    setError(false)
    api.get(`transaction/tx/volumes/`, { filterType: 'operationType', filterValue: 'SEND', txStatus: 'PENDING', period: 'LastHour' }, { useHeaders: true, useQuery: true })
      .then(data => {
        setLoading(false);
        if(data.error){ 
          setError(true)
          console.log(data.error)
        } else {
          setData(data)
        }
      })
  }

  const onSubmit = () => {
    setLoading(true)
    setError(false)
    const params = { filterType: 'operationType', filterValue: queries.filterValue }
    if(queries.txStatus){
      params.txStatus = queries.txStatus
    }
    if(queries.period){
      params.period = queries.period
    }
    if(startDate){
      params.startDate = startDate
    }
    if(endDate){
      params.endDate = endDate
    }
    api.get(`transaction/tx/volumes/`, params, { useHeaders: true, useQuery: true })
      .then(data => {
        setLoading(false);
        if(data.error){ 
          setError(true)
          console.log(data.error)
        } else {
          setData(data)
        }
      }) 
  }

  return(
    <Box>
      {
        (status === 'resolved' || status === 'rejected' ) && loading === false && error === false &&
        <>
          <Box className={ classNames([styles['filter-wrapper'], darkMode ? styles['dark']: ''])}>
            <Box flex={'100%'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
              <Button
                size={'sm'}
                colorScheme={'teal'} 
                color={'white'} 
                onClick={onSubmit}
                disabled={queries.period === 'Custom' && (!startDate || !endDate)}
              >Refresh <MdRefresh /></Button>
            </Box>
            {/* <Box display={'flex'} flex={'100%'} marginBottom={'0px'}>
              <Text fontSize={'15px'} margin={'0px 0px'} alignSelf={'center'}>Filter</Text>
              <BsFilter size={'25px'}/>
            </Box> */}

            <Box className={styles['filter']}>
              <label>Operation Type</label>
              <select name={'filterValue'} className={styles['select']} onChange={queryChange} value={queries.filterValue}>
                <option value={'SEND'}>Send</option>
                <option value={'RECEIVE'}>Receive</option>
                <option value={'SELL'}>Sell</option>
                <option value={'BUY'}>Buy</option>
                <option value={'SWAP'}>Swap</option>
                <option value={'P2P_TOPUP'}>P2P Topup</option>
                <option value={'P2P_WITHDRAWAL'}>P2P Withdrawal</option>
              </select>
            </Box>
            
            <Box className={styles['filter']}>
              <label>Transaction Status</label>
              <select name={'txStatus'} className={styles['select']} onChange={queryChange} value={queries.txStatus}>
                <option value={'COMPLETED'}>Completed</option>
                <option value={'ALL_STATUS'}>All Statuses</option>
                <option value={'PENDING'}>Pending</option>
                <option value={'INITIATED'}>Initiated</option>
                <option value={'ON_HOLD'}>On Hold</option>
                <option value={'RELEASED'}>Released</option>
                <option value={'FAILED'}>Failed</option>
                <option value={'CANCELED'}>Cancelled</option>
                <option value={'ALL_PENDING'}>All Pending</option>

              </select>
            </Box>
            
            <Box className={styles['filter']}>
              <label>Date Period</label>
              <select name={'period'} className={styles['select']} onChange={queryChange} value={queries.period}>
                <option value={'LastHour'}>Last Hour</option>
                <option value={'Daily'}>Daily</option>
                <option value={'Yesterday'}>Yesterday</option>
                <option value={'ThisWeek'}>This Week</option>
                <option value={'ThisMonth'}>This Month</option>
                <option value={'TwoMonths'}>Two Months</option>
                <option value={'Custom'}>Custom</option>
              </select>
            </Box>
          </Box>
          <Collapse in={queries.period === 'Custom'} animateOpacity>
            <Box  display={'flex'} flexDirection={'column'} alignItems={'flex-end'} backgroundColor={darkMode ? 'rgb(169, 173, 172)' : 'rgb(192, 189, 186, 0.2)'} color={'black'} marginBottom={'20px'}>
              <Box className={styles['date-picker-wrapper']}>
                <Box className={styles['date-picker']}>
                  <label>Start Date:</label>
                  <input type='date' onChange={e => setStartDate(e.target.value)} value={startDate}/>
                </Box>
                <Box className={styles['date-picker']}>
                  <label>End Date:</label>
                  <input type='date' onChange={e => setEndDate(e.target.value)} value={endDate}/>
                </Box>
              </Box>
            </Box>
          </Collapse>
          
          
          <VStack padding={'10px 100px'} divider={<StackDivider borderColor='gray.200' />}>
            {
              currencyList.map((item, index) => (
                <CurrencyCard item={item} key={index} currencyImages={currencyImages} data={data}/>
              ))
            }
          </VStack>
        </>
      }

      {
        (status === 'resolved' || status === 'rejected' ) && loading === false && error === true &&
        <>
          <Retry message={'There was an error'} callback={fetchDefault}/>
        </>
      }

      {
        ( status === 'pending' || loading === true ) &&
        <>
          <Loader />
        </>
      }
      
    </Box>
  )
}