export const linkReqConstants =
{
    GETALL_REQUEST: "GETALL_LINK_REQUESTS",
    GETALL_SUCCESS: "GETALL_LINK_SUCCESS",
    GETALL_FAILURE: "GETALL_LINK_FAILURE",

    GET_BY_ID_REQUEST: "GET_LINK_BY_ID_REQUEST",
    GET_BY_ID_SUCCESS: "GET_LINK_BY_ID_SUCCESS",
    GET_BY_ID_FAILURE: "GET_LINK_BY_ID_FAILURE",

    LINK_RESET: "LINK_RESET"
};
