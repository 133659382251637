import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import CurrencyInput from 'react-currency-input-field';

import styles from './Input.module.scss';


function Text ( props ) 
{
    const {
        label,
        id,
        type,
        placeholder,
        readOnly
    } = props;
    const [ field, meta/*, helpers */ ] = useField( props );
    return (
        <div className={ styles.fieldGroup }>
            <label
                className={ styles.label }
                htmlFor={ id }>{ label }</label>
            <input
                className={ classNames( styles.text,
                    {
                        [ styles.disabled ]: readOnly
                    } ) }
                type={ type || "text" }
                placeholder={ placeholder }
                { ...field }
                { ...props } />
            { meta.touched && meta.error ? (
                <small className={ styles.error }>{ meta.error }</small>
            ) : null }

        </div>

    );
}

function MyCurrencyInput ( props ) 
{
    const {
        label,
        id,
        handleChange,
        placeholder,
        readOnly,
        value
    } = props;
    const [ /*field*/, meta/*, helpers */ ] = useField( props );
    return (
        <div className={ styles.fieldGroup }>
            <label
                className={ styles.label }
                htmlFor={ id }>{ label }</label>

            <CurrencyInput
                className={ classNames( styles.text,
                    {
                        [ styles.disabled ]: readOnly
                    } ) }
                placeholder={ placeholder }
                allowNegativeValue={ false }
                onValueChange={ ( value, name ) => handleChange( value, name ) }
                value={ value }
            />

            { meta.touched && meta.error ? (
                <small className={ styles.error }>{ meta.error }</small>
            ) : null }

        </div>

    );
}

export { Text, MyCurrencyInput };
