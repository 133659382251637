import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from "react-router-dom";

import notFound from "../../assets/images/notFound.png";
import "./NotFound.scss";

function NotFound ( { url } )
{
    let { pathname } = useLocation();
    const isDb = pathname.includes( "dashboard" );

    return (
        <div className={ classNames( "NotFound", { db: isDb } ) }>
            <section>
                <div className="error-illustration">
                    <img src={ notFound } alt="not found illustration" />
                </div>
                <h2 className="header">
                    OOPS
                </h2>
                <p className="message">
                    We're sorry but we can't find the <br /> page you're looking for.
                </p>
                <div className="cta-row">

                    <Link to={ url || "/" } >Back Home
                    </Link>
                </div>

            </section>
        </div >
    );
}

export { NotFound };
