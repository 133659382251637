import { linkReqConstants } from "../constants";

import 
{
    request,
    failure,
    getAllSuccess
} from "./utils";

const initState =
{
    totalPages: 0,
    totalElements: 0,
    first: true,
    last: false,
    number: 0,
    size: 24,
    content: [],
    error: null,
    status: "idle",
    realCount: 0
};

export function linkRequests ( state = initState, action )
{
    switch ( action.type ) 
    {
        case linkReqConstants.GETALL_REQUEST:
            return request( state );

        case linkReqConstants.GETALL_FAILURE:
            return failure( state, action.payload );

        case linkReqConstants.GETALL_SUCCESS:
            return getAllSuccess( state, action.payload );

        case linkReqConstants.LINK_RESET:
            return initState;

        default:
            return state;
    }
}
