import React from 'react';
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import {
    Table as ChakraTable,
    Tbody,
    TableContainer,
  } from '@chakra-ui/react'

// import styles from './Table.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';


function Table ( { data, headers, columns, rowClickHandler, history, currentPage, size } )
{
    const { darkMode } = useSelector( state => state.theme )
    const styles = darkMode ? require("./TableDark.module.scss") : require("./Table.module.scss")

    const getSN = (index) => {
        if(currentPage && size){
            const indexOfFirstElementOnPage = (currentPage + 1) * size - (size)
            return indexOfFirstElementOnPage + index 
        }
        else {
            return index
        }
    }
    return (
        <TableContainer overflowY={'scroll'} className={styles['table-adjusted']}>

            <ChakraTable variant={'simple'} size={'md'} colorScheme={'blackAlpha'} className={classNames(styles.reducedPadding)}>
                <TableHeader headers={ headers } />
                <Tbody>

                    {
                        data.map( ( row, index ) => (
                            <TableRow key={ index }
                                rowData={ row }
                                columns={ columns }
                                ind={ getSN(index) }
                                history={ history }
                                rowClickHandler={ (e) =>  rowClickHandler(row) }
                            />
                        ) )
                    }
                </Tbody>

            </ChakraTable>
        </TableContainer>
    );
}

export { Table };
