import { api } from '../../utils/api'
import { userConstants } from '../constants'
import { dispatcher } from './utils'

const {
    PAGE_SIZE,
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    CREATE_REQUEST,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILURE,
    RESET_USERS,
} = userConstants

const getAll = ( page = 1, kycStatus = undefined ) => async dispatch => {
    dispatch( dispatcher( GETALL_REQUEST ) )

    const argument = { page, size: PAGE_SIZE, kycStatus }
    if (!kycStatus ){
        delete argument.kycStatus
    }
    const data = await api.get( 'users' , argument, { useHeaders: true, useQuery: true} )

    if (data.error) {
        dispatch( dispatcher( GETALL_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( GETALL_SUCCESS, data ) )
    }
}

const search = (page = 1, searchTerm, kycStatus = undefined) => async dispatch => {
    dispatch( dispatcher( GETALL_REQUEST) )

    const argument = { page, searchTerm, size: PAGE_SIZE, }
    if (!kycStatus){
        delete argument.kycStatus
    }
  
    const data = await api.get( 'users', argument, { useHeaders: true, useQuery: true} )
  
    if (data.error) {
      dispatch( dispatcher( GETALL_FAILURE, data.error ) )
    } else {
      dispatch( dispatcher( GETALL_SUCCESS, data ) )
    }
  }

const filter = (page = 1, filters) => async dispatch => {
    dispatch(dispatcher(GETALL_REQUEST));
    const argument = { page, ...filters, size: PAGE_SIZE, };
    // console.log(argument)

    const data = await api.get('users', argument, { useHeaders: true, useQuery: true });

    if (data.error) {
        dispatch(dispatcher(GETALL_FAILURE, data.error));
    } else {
        dispatch(dispatcher(GETALL_SUCCESS, data));
    }
}

const creatUser = user => async dispatch => {
    dispatch( dispatcher( CREATE_REQUEST ) )

    const data = await api.post( 'users', user )

    if (data.error) {
        dispatch( dispatcher( CREATE_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( CREATE_SUCCESS, data ) )
    }
}

const editUser = ( user ) => async dispatch => {
    dispatch( dispatcher( UPDATE_REQUEST ) )

    const data = await api.put( `users/${user.id}`, user )

    if (data.error) {
        dispatch( dispatcher( UPDATE_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( UPDATE_SUCCESS, data ) )
    }
}

const deleteUser = id => async dispatch => {
    dispatch( dispatcher( DELETE_REQUEST ) )

    const data = await api.del(`users/${id}`)

    if (data.error) {
        dispatch( dispatcher( DELETE_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( DELETE_SUCCESS, { data, id } ) )
    }
}

const reset = () => async dispatch => {
    dispatch( dispatcher( RESET_USERS ) )
}

export const userActions =
{
    getAll,
    search,
    filter,
    creatUser,
    editUser,
    deleteUser,
    reset,
};