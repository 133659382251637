import { adminUserConstants } from '../constants'

import {
    request,
    failure
} from './utils'

const {
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    RESET_ADMIN_USERS
} = adminUserConstants

const initialState = {
  content: [],
  error: null,
  status: "idle"
}

export const adminUsers = ( state = initialState, action ) => {
    switch (action.type) {
        case GETALL_REQUEST:
          return request(state);        
        
        case GETALL_FAILURE:
          return failure( state, action.payload )

        case GETALL_SUCCESS:
          return {
              ...state,
              error: null,
              status: 'resolved',
              loading: 'false',
              size: action.payload.size,
              content: [ ...action.payload ]
          }
        case RESET_ADMIN_USERS:
          return initialState

        default:
          return state;
    }
}