const BLOCKCHAIN_TYPE = {
  ACCOUNT_BASED: 'Account Based',
  UTXO: 'UTXO'
}

const CURRENCY_TYPE = {
  CRYPTO: 'Crypto',
  FIAT: 'Fiat'
}

const FEE_TYPE = {
  PERCENT: 'Percent',
  FIXED: 'Fixed'
}

let TX_OPERATION = {
  RECEIVE: 'Receive',
  SEND: 'Send',
  SELL: 'Sell',
  BUY: 'Buy',
  SWAP: 'Swap',
  INVOICE: 'INVOICE',
  P2P_TOPUP: 'P2P_TOPUP',
  P2P_WITHDRAWAL: 'P2P_WITHDRAWAL',
};

const MockFee = [
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
  {
    id: 'abc',
    minValue: 0,
    maxValue: 10,
    value: 0.2,
    txOperation: 'SELL' //enum
  },
]

const MockCurrency = [
  {
    id: 'abc',
    name: 'USDC',
    displayName: 'USDC',
    fullName: 'United States Dollar Crypto',
    network: 'ETH',
    blockchainType: 'ACCOUNT_BASED',  //Enum
    isToken: true,
    currencyType: 'FIAT',    //Enum
    feeType: 'PERCENT',   //Enum
    imgUrl: '_',
    fees: MockFee
  },
  {
    id: 'abc',
    name: 'USDC',
    displayName: 'USDC',
    fullName: 'United States Dollar Crypto',
    network: 'ETH',
    blockchainType: 'ACCOUNT_BASED',  //Enum
    isToken: true,
    currencyType: 'FIAT',    //Enum
    feeType: 'PERCENT',   //Enum
    imgUrl: '_',
    fees: MockFee
  },
  {
    id: 'abc',
    name: 'USDC',
    displayName: 'USDC',
    fullName: 'United States Dollar Crypto',
    network: 'ETH',
    blockchainType: 'ACCOUNT_BASED',  //Enum
    isToken: true,
    currencyType: 'FIAT',    //Enum
    feeType: 'PERCENT',   //Enum
    imgUrl: '_',
    fees: MockFee
  },
  {
    id: 'abc',
    name: 'USDC',
    displayName: 'USDC',
    fullName: 'United States Dollar Crypto',
    network: 'ETH',
    blockchainType: 'ACCOUNT_BASED',  //Enum
    isToken: true,
    currencyType: 'FIAT',    //Enum
    feeType: 'PERCENT',   //Enum
    imgUrl: '_',
    fees: MockFee
  },
  {
    id: 'abc',
    name: 'USDC',
    displayName: 'USDC',
    fullName: 'United States Dollar Crypto',
    network: 'ETH',
    blockchainType: 'ACCOUNT_BASED',  //Enum
    isToken: true,
    currencyType: 'FIAT',    //Enum
    feeType: 'PERCENT',   //Enum
    imgUrl: '_',
    fees: MockFee
  },
]

const currencyConstants = {
  GETALL_REQUEST: 'CURRENCIES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CURRENCIES_GETALL_SUCCESS',
  GETALL_FAILURE: 'CURRENCIES_GETALL_FAILURE',

  CREATE_REQUEST: 'CURRENCIES_CREATE_REQUEST',
  CREATE_SUCCESS: 'CURRENCIES_CREATE_SUCCESS',
  CREATE_FAILURE: 'CURRENCIES_CREATE_FAILURE',
  
  UPDATE_REQUEST: 'CURRENCIES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CURRENCIES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CURRENCIES_UPDATE_FAILURE',

  CREATE_FEE_REQUEST: 'FEE_CREATE_REQUEST',
  CREATE_FEE_SUCCESS: 'FEE_CREATE_SUCCESS',
  CREATE_FEE_FAILURE: 'FEE_CREATE_FAILURE',

  UPDATE_FEE_REQUEST: 'FEE_UPDATE_REQUEST',
  UPDATE_FEE_SUCCESS: 'FEE_UPDATE_SUCCESS',
  UPDATE_FEE_FAILURE: 'FEE_UPDATE_FAILURE',

  DELETE_FEE_REQUEST: 'FEE_DELETE_REQUEST',
  DELETE_FEE_SUCCESS: 'FEE_DELETE_SUCCESS',
  DELETE_FEE_FAILURE: 'FEE_DELETE_FAILURE',

  RESET_CURRENCIES: "RESET_CURRENCIES",
  RESET_FEE_STATUS: "RESET_FEE_STATUS"
}


export {
  MockCurrency,
  MockFee,
  BLOCKCHAIN_TYPE,
  CURRENCY_TYPE,
  FEE_TYPE,
  TX_OPERATION,
  currencyConstants
}