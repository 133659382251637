import { useState } from 'react';

function useTableRoles({ content, size, totalPages }){
  
  const [ currentPage, setCurrentPage ] = useState(0)

  const changePage = ( page ) =>
    {
        setCurrentPage( page - 1 );
    };

  const incPage = () =>
  {
      const p = currentPage + 1;
      if ( p >= totalPages )
      {
          changePage( totalPages  );
      }
      else
      {
          changePage( p  );
      }
  };

  const decPage = () =>
  {
      const p = currentPage - 1;
      if ( p < 1 )
      {
          changePage( 1 );
      }
      else
      {
          changePage( p );
      }
  };


    return {
      currentPage,
      incPage,
      decPage,
      changePage
    };

}

export { useTableRoles }