import { api } from '../../utils/api';
import { storeFrontCategoryConstant } from '../constants';
import { dispatcher } from './utils';

const {
  GETALL_FAILURE, 
  GETALL_REQUEST,
  GETALL_SUCCESS,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  RESET_TRANSACTIONS,
  DELETE_REQUEST,
  DELETE_FAILURE,
  DELETE_SUCCESS
} = storeFrontCategoryConstant;

const getAll = () => async dispatch => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.get('marketplace-config/categories', {}, { useHeaders: true });

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};

const createCategory = currency => async dispatch => {
  dispatch(dispatcher(CREATE_REQUEST));

  const data = await api.post('marketplace-config/categories', currency);

  if (data.error) {
    dispatch(dispatcher(CREATE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(CREATE_SUCCESS, data));
  }
};

const editCategory = (currency) => async dispatch => {
  dispatch(dispatcher(UPDATE_REQUEST));

  const data = await api.put(`marketplace-config/categories/${currency.id}`, currency);

  if (data.error) {
    dispatch(dispatcher(UPDATE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(UPDATE_SUCCESS, currency));
  }
};

const deleteCategory = id => async dispatch => {
  dispatch(dispatcher(DELETE_REQUEST));

  const data = await api.del(`marketplace-config/categories/${id}`);

  if (data.error) {
    dispatch(dispatcher(DELETE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(DELETE_SUCCESS, data));
  }
}

const reset = () => async dispatch => {
  dispatch(dispatcher(RESET_TRANSACTIONS));
};

const fetchProducts = async ({
  page = 1,
  size = 25,
  dir = 'DESC',
  searchTerm = '',
  status = ''
 }) => {
  let url = `marketplace-config/products?page=${page}&size=${size}&dir=${dir}`
  if (!!searchTerm) {
    url += `&searchTerm=${searchTerm}`
  }
  if (!!status) {
    url += `&status=${status}`
  }
  const data = await api.get(url, {}, { useHeaders: true });

  return data;
}

const updateProductStatus = async (productId, status) => {
  const data = await api.put(`marketplace-config/products/${productId}/status`, { status });

  return data;
}

export const storeFrontActions =
{
  getAll,
  createCategory,
  editCategory,
  reset,
  deleteCategory,
  fetchProducts,
  updateProductStatus,
};