import { api } from '../../utils/api'
import { currencyConstants } from '../constants'
import { dispatcher } from './utils'

const {
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    CREATE_REQUEST,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    CREATE_FEE_REQUEST,
    CREATE_FEE_SUCCESS,
    CREATE_FEE_FAILURE,
    UPDATE_FEE_REQUEST,
    UPDATE_FEE_SUCCESS,
    UPDATE_FEE_FAILURE,
    DELETE_FEE_REQUEST,
    DELETE_FEE_SUCCESS,
    DELETE_FEE_FAILURE,
    RESET_CURRENCIES,
    RESET_FEE_STATUS
} = currencyConstants

const getAll = () => async dispatch => {
    dispatch( dispatcher( GETALL_REQUEST ) )

    const data = await api.get( 'currency-config', {}, { useHeaders: true })

    if (data.error) {
        dispatch( dispatcher( GETALL_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( GETALL_SUCCESS, data ) )
    }
}

const createCurrency = currency => async dispatch => {
    dispatch( dispatcher( CREATE_REQUEST ) )

    const data = await api.post( 'currency-config', currency )

    if (data.error) {
        dispatch( dispatcher( CREATE_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( CREATE_SUCCESS, data ) )
    }
}

const editCurrency = ( currency ) => async dispatch => {
    dispatch( dispatcher( UPDATE_REQUEST ) )

    const data = await api.put( `currency-config/${currency.id}`, currency )

    if (data.error) {
        dispatch( dispatcher( UPDATE_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( UPDATE_SUCCESS, currency ) )
    }
}

const reset = () => async dispatch => {
    dispatch( dispatcher( RESET_CURRENCIES ) )
    dispatch( dispatcher( RESET_FEE_STATUS ) )
}

const createFee = fee => async dispatch => {
  dispatch( dispatcher( CREATE_FEE_REQUEST ) )

  const data = await api.post( 'currency-config/fee', fee )

  if (data.error) {
      dispatch( dispatcher( CREATE_FEE_FAILURE, data.error ) )
  } else {
      dispatch( dispatcher( CREATE_FEE_SUCCESS, data ) )
  }
}

const editFee= ( fee ) => async dispatch => {
  dispatch( dispatcher( UPDATE_FEE_REQUEST ) )

  const data = await api.put( `currency-config/fee/${fee.id}`, fee )

  if (data.error) {
      dispatch( dispatcher( UPDATE_FEE_FAILURE, data.error ) )
  } else {
      dispatch( dispatcher( UPDATE_FEE_SUCCESS, fee ) )
  }
}

const deleteFee = payload => async dispatch => {
  dispatch( dispatcher( DELETE_FEE_REQUEST ) )
  const { id } = payload
  const data = await api.del(`currency-config/fee/${id}`)

  if (data.error) {
      dispatch( dispatcher( DELETE_FEE_FAILURE, data.error ) )
  } else {
      dispatch( dispatcher( DELETE_FEE_SUCCESS, payload ) )
  }
}

export const currencyActions =
{
    getAll,
    createCurrency,
    editCurrency,
    createFee,
    editFee,
    deleteFee,
    reset
};