import { dispatcher } from './utils'


const toggle = () => async dispatch => {
    dispatch( dispatcher( "TOGGLE" ) )
}

export const themeActions =
{
    toggle
};