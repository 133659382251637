import { api } from "../../utils/api";
import { sharePairConstants } from "../constants";
import { dispatcher } from "./utils";


const getAllSharePairs = ( page = 1 ) => async ( dispatch ) =>
{
    dispatch( dispatcher( sharePairConstants.GETALL_SP_REQUEST ) );

    const data = await api.get( "sharepair", {
        page
    }, { useHeaders: true } );

    if ( data.error )
    {
        dispatch( dispatcher( sharePairConstants.GETALL_SP_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( sharePairConstants.GETALL_SP_SUCCESS, data ) );
    }

};

const editSharePair = ( pair ) => async ( dispatch ) =>
{
    dispatch( dispatcher( sharePairConstants.EDIT_SP_REQUEST ) );

    const data = await api.put( `sharepair/${ pair.id }`, pair, {}, false, true );

    if ( data.error )
    {
        dispatch( dispatcher( sharePairConstants.EDIT_SP_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( sharePairConstants.EDIT_SP_SUCCESS, pair ) );
    }

};



const resetEdit = () => async ( dispatch ) =>
{
    dispatch( dispatcher( sharePairConstants.RESET_EDIT ) );
};

const reset = () => async ( dispatch ) =>
{
    dispatch( dispatcher( sharePairConstants.RESET_SP ) );
};


export const sharePairActions =
{
    getAllSharePairs,
    editSharePair,
    reset,
    resetEdit,
};
