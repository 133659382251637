import { api } from '../../utils/api'
import { referralStatisticsConstants} from '../constants'
import { dispatcher } from './utils'

const {
  GETALL_REQUEST,
  GETALL_FAILURE,
  GETALL_SUCCESS,
  PAGE_SIZE,
  RESET_REFERRAL_STATISTICS
} = referralStatisticsConstants

const getAll = ( page = 1 ) => async dispatch => {
  dispatch( dispatcher( GETALL_REQUEST) )

  const data = await api.get( 'dashboard/referrals', { page, size: PAGE_SIZE, }, { useHeaders: true, useQuery: true} )

  if (data.error) {
    dispatch( dispatcher( GETALL_FAILURE, data.error ) )
  } else {
    dispatch( dispatcher( GETALL_SUCCESS, data ) )
  }
}
const reset = () => async dispatch => {
  dispatch( dispatcher(RESET_REFERRAL_STATISTICS))
}

const searchReferrals = (searchType, searchValue) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.get(
    'dashboard/referrals',
    { searchType, searchValue },
    { useHeaders: true, useQuery: true },
  );


  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};

const filter = (page = 1, filters) => async dispatch => {
  dispatch(dispatcher(GETALL_REQUEST));
  const argument = { page, ...filters, size: PAGE_SIZE, };

  const data = await api.get('dashboard/referrals', argument, { useHeaders: true, useQuery: true });

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
}

export const referralStatisticsActions = {
  getAll,
  reset,
  searchReferrals,
  filter
};