import { api } from '../../utils/api';
import { ambassadorConstants, transactionsConstants } from '../constants';
import { dispatcher } from './utils';

const {
  GETALL_REQUEST,
  GETALL_FAILURE,
  GETALL_SUCCESS,
  PAGE_SIZE,
  RESET_TRANSACTIONS,
} = ambassadorConstants;

const getAllTransactions =
  (page = 1) =>
  async (dispatch) => {
    dispatch(dispatcher(GETALL_REQUEST));

    const data = await api.get(
      'transaction/ambassador/transactions',
      { page, size: PAGE_SIZE },
      { useHeaders: true, useQuery: true },
    );

    if (data.error) {
      dispatch(dispatcher(GETALL_FAILURE, data.error));
    } else {
      dispatch(dispatcher(GETALL_SUCCESS, data));
    }
  };

const filter = (page = 1, filters) => async dispatch => {
  dispatch(dispatcher(GETALL_REQUEST));
  const argument = { page, ...filters, size: PAGE_SIZE, };
  // console.log(argument)
 
  const data = await api.get('transaction/ambassador/transactions', argument, {
    useHeaders: true,
    useQuery: true,
  });
  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
}


const checkTxFee =
  (id) =>
    async (dispatch) => {
      dispatch(dispatcher(GETALL_REQUEST));

      
    };

const searchTransaction = (username) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.get(
    'transaction/ambassador/transactions',
    { ...username },
    { useHeaders: true, useQuery: true },
  );
  

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};
const reset = () => async (dispatch) => {
  dispatch(dispatcher(RESET_TRANSACTIONS));
};

export const ambassadorActions = {
  getAllTransactions,
  searchTransaction,
  reset,
  checkTxFee,
  filter
};
