import { api } from '../../utils/api';
import { userWallerBalancesConstants } from '../constants';
import { dispatcher } from './utils';

const { GETALL_REQUEST, GETALL_SUCCESS, GETALL_FAILURE, RESET_ROOTWALLET } =
  userWallerBalancesConstants;

const getAll = () => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.get(
    'users/virtual-accounts/balances',
    {},
    { useHeaders: true, useQuery: true },
  );

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};

const reset = () => async (dispatch) => {
  dispatch(dispatcher(RESET_ROOTWALLET));
};

export const userWallerBalancesAction = {
  getAll,
  reset,
};
