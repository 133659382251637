import { sharePairConstants } from '../constants';

import 
{
    request,
    failure,
    getAllSuccess
} from "./utils";


const initialState =
{
    content: [],
    status: "idle",
    realCount: 0,
    totalPages: 0,
    totalElements: 0,
    first: true,
    last: false,
    number: 0,
    size: 24,
    error: null,

    editStatus: "idle",
    editErr: null

};

export function sharePairs ( state = initialState, action )
{
    const { type, payload } = action;
    const content = [ ...state.content ];

    switch ( type ) 
    {
        case sharePairConstants.GETALL_SP_REQUEST:
            return request( state );

        case sharePairConstants.GETALL_SP_FAILURE:
            return failure( state, action.payload );

        case sharePairConstants.GETALL_SP_SUCCESS:
            return getAllSuccess( state, action.payload );


        case sharePairConstants.EDIT_SP_REQUEST:
            return {
                ...state,
                editStatus: "pending",
                editErr: null
            };

        case sharePairConstants.EDIT_SP_FAILURE:
            return {
                ...state,
                editStatus: "rejected",
                editErr: payload
            };

        case sharePairConstants.EDIT_SP_SUCCESS:
            const index = content.findIndex( s => s.id === payload.id );
            content[ index ] = payload;
            return {
                ...state,
                editStatus: "resolved",
                editErr: null,
                content,
            };

        case sharePairConstants.RESET_EDIT:
            return {
                ...state,
                editStatus: "idle",
                editErr: null
            };

        case sharePairConstants.RESET_SP:
            return initialState;

        default:
            return state;
    }

}
