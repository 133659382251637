import {affiliateConstants} from '../constants'
import 
{
    request,
    failure,
    getAllSuccess
} from "./utils";

const initialState = {
    totalPages: 0,
    totalElements: 0,
    first: true,
    last: false,
    number: 0,
    size: 24,
    content: [],
    error: null,
    status: "idle",
    realCount: 0
}

export const affiliates = ( state = initialState, action ) => {
    switch ( action.type ) 
    {
        case affiliateConstants.GETALL_REQUEST:
            return request( state );

        case affiliateConstants.GETALL_FAILURE:
            return failure( state, action.payload );

        case affiliateConstants.GETALL_SUCCESS:
            return getAllSuccess( state, action.payload );

        case affiliateConstants.AFFILIATES_RESET:
            return initialState;

        default:
            return state;
    }
}