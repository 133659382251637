import 
{
    format,
    lastDayOfMonth,
    startOfMonth,
    addDays,
    addHours,
    addMinutes
} from "date-fns";

const getObjProp = ( path, obj ) =>
{
    return path.split( '.' ).reduce( function ( prev, curr )
    {
        return prev ? prev[ curr ] : null;
    }, obj );
};

const nullValue = () => "N/A";


const getUnFractionedTime = ( number ) =>
{
    const split = number.toString().split( "." );
    const whole = split[ 0 ];

    const mantissa = split[ 1 ];

    if ( !mantissa )
    {
        return +whole;
    }


    return +`${ whole }${ mantissa }`;
};

const dateFormatter = ( value ) =>
{
    const date = new Date( getUnFractionedTime( value ) );
    return date;
};

const fixDecimalDate = value =>
{
    return dateFormatter( value );
};

const getAsset = value =>
{
    return value.split( " " )[ 1 ];
};

const getAssetValue = value =>
{
    return value ? value.split( " " )[ 0 ] : "N/A";
};

const getNaira = value =>
{
    return value ? value.split( " " )[ 1 ] : "N/A";
};

const formatCurrency = ( value ) =>
{
    return +value ? ( new Intl.NumberFormat( 'en-NG' ) ).format( value ) : 0;
};

const formatDecimalCurrency = ( value ) => {
    let decimal = parseFloat(value) - parseFloat(value).toFixed(0) 
    decimal = `${decimal}`
    decimal = decimal.split('.')[1]
    const valueWithoutDecimal = parseInt(value)
    const formattedValue = `${formatCurrency(valueWithoutDecimal)}`
    if(decimal === undefined){
        return formattedValue
    }
    else {
        return formattedValue + '.' + decimal
    }
}

// filter out nested objects of array that are null
const _delNestedNullObj = ( con ) =>
{
    Object.keys( con ).forEach( key =>
    {
        if ( con[ key ] && typeof con[ key ] === 'object' ) _delNestedNullObj( con[ key ] );

        // if (con[key] === undefined || con[key] === null) con[key] = 'N/A'
        if ( con[ key ] === undefined || con[ key ] === null ) delete con[ key ];
    }
    );
    return con;
};

const generateYears = () =>
{
    const base = 2021;
    const thisYear = new Date().getFullYear();
    const diff = thisYear - base;
    const list = [];

    for ( let i = 0; i <= diff; i++ ) 
    {
        list.push( base + i );

    }
    return list;
};

const formatDate = ( value, style = "yyyy/LL/dd" ) =>
{
    if ( !value ) return "N/A";
    return format( new Date( value ), style );

};

const getLastDay = () =>
{
    const last = new Date( lastDayOfMonth( new Date() ) );

    return formatDate( last );
};

const getFirstDay = () =>
{
    const first = new Date( startOfMonth( new Date() ) );

    return formatDate( first );
};

const getLast30Days = () =>
{
    return addDays( new Date(), -30 );
};

const getHoursAgo = ( date, diff = -1 ) =>
{
    return addHours( new Date( date ), diff );
};

const tickFormatter = ( mode ) =>
{
    switch ( mode )
    {
        case "day":
            return ( x ) => formatDate( x );

        default:
            return ( x ) => formatDate( x, "HH:mm" );
    }
};

const addTime = ( date, mode, span = 1 ) =>
{
    const start = new Date( date );
    let endDate;

    switch ( mode ) 
    {
        case "minute":
            endDate = addMinutes( start, span );
            break;

        case "hour":
            endDate = addHours( start, span );
            break;

        default:
            endDate = addDays( start, span );
            break;
    }

    return endDate;
};

/**
   * @description generates slice of data to display on table for the specified page table
   * @param {Array} content 
   * @param {number} page 
   * @param {number} size 
   * @param {number} totalPages
   * @returns 
   */
const pageData = ( content, page, size, totalPages ) => {
    page = page + 1
    const start = ( page * size ) - size
    if( page === totalPages ) {
        return content.slice(start)
    }
    const end = ( page * size )
    return content.slice(start, end)
}

/**
 * 
 * @param {*} file 
 * @returns Base64 String
 */
const convertToBase64 = (file) =>{
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result)
        }
        fileReader.onerror = error => {
            reject(error)
        }
    })
}

const randomColor = () => {
    const randomNumber = () => {
        return Math.floor(Math.random() * 160)
    }
    return `rgb(${randomNumber()}, ${randomNumber()}, ${randomNumber()})`
}

export 
{
    getObjProp,
    nullValue,
    formatCurrency,
    formatDecimalCurrency,
    fixDecimalDate,
    getAsset,
    getAssetValue,
    getNaira,
    _delNestedNullObj,
    generateYears,
    formatDate,
    getLastDay,
    getFirstDay,
    getLast30Days,
    getHoursAgo,
    tickFormatter,
    addTime,
    pageData,
    convertToBase64,
    randomColor
};
