import classNames from 'classnames';
import React from 'react';
import { BiSearch } from 'react-icons/bi';
import { BsX } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import styles from './Search.module.scss';


const Search = ( { placeholder, changeHandler, value, autofocus, parameters, parameterChange, onSearchClick, cancel, showCancel, ...props } ) =>
{
    const { darkMode } = useSelector( state => state.theme )
    return (
        <div className={classNames([styles.search, darkMode ? styles['dark']: ''])}>
            {
                parameters &&
                    <select className={ styles.select } onChange={ parameterChange }>
                        {   
                            parameters.map((parameter, index) => (
                             <option key={index} value={parameter}>{parameter}</option>
                            ))
                        }
                    </select>
            }
            <input
                autoFocus={ autofocus }
                value={ value }
                onChange={ changeHandler }
                placeholder={ placeholder }
                type="text" {...props}/>
            <BiSearch onClick={ onSearchClick}/>
            { showCancel && <BsX onClick={ cancel} className={styles.cancel}/>}
            
        </div>
    );
};

export { Search };
