import React, { useEffect, useState } from 'react';
import classNames from "classnames";

import
{
    Page,
    MyPie,
    PieKeys,
    MyBarChart,
    Profit,
    TransactionVolume,
} from "../../../components";

import { randomColor } from "../../../utils/helpers";
// import styles from './Home.module.scss';
import { api } from '../../../utils/api';
import { Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { useSelector } from 'react-redux';


const Home = () =>
{
    const { darkMode } = useSelector(state => state.theme )
    const styles = darkMode ? require('./HomeDark.module.scss') : require('./Home.module.scss')
    const [ transactionStatusData, setTransactionStatusData ] = useState()
    const [ cryptoTransactionsData, setCryptoTransactionsData ] = useState()
    const [ p2pStatusData, setP2pStatusData ] = useState()
    const [ profitData, setProfitData ] = useState()
    const [ totalTxData, setTotalTxData ] = useState({})

    useEffect(() => {
        api.get('dashboard/transaction-status-percentages')
            .then(response => {
                if(response.error){
                    console.log(response.error)
                } 
                else {
                    const newData = [
                        {
                            quarter: "Pending",
                            fill: "#838280",
                            earnings: response.pending
                        },
                        {
                            quarter: "Failed",
                            fill: "#da0a0a",
                            earnings: response.failed
                        },
                        {
                            quarter: "Successful",
                            fill: "#17f017",
                            earnings: response.successful
                        },
                        {
                            quarter: "On Hold",
                            fill: "#bfc3c5",
                            earnings: response['on Hold']
                        },
                        {
                            quarter: "Cancelled",
                            fill: "#f15d5d",
                            earnings: response.cancelled
                        },
                        {
                            quarter: "Released",
                            fill: "#6DC4F2",
                            earnings: response.released
                        },
                        {
                            quarter: "Initiated",
                            fill: "#2427e9",
                            earnings: response.initiated
                        },
                    ]
                    setTransactionStatusData(newData)
                }
            })
    }, [])

    useEffect(() => {
        api.get('dashboard/currencies-status-percentages')
            .then(resData => {
                if(resData.error){
                    console.log(resData.error)
                }
                else{
                    const pieData = []
                    for(let currency of resData){
                        if(currency.percent!==0) 
                        pieData.push({
                            x: currency.currency,
                            y: currency.percent,
                            fill: randomColor()
                        })
                    }
                    setCryptoTransactionsData(pieData)
                }
            })
    }, [])

    useEffect(() => {
        api.get('dashboard/p2p-data')
            .then( response => {
                if(response.error){
                    console.log(response.error)
                }
                else {
                    let p2pdata = []
                    const keys = Object.keys(response)
                    for(let key of keys){
                        if(key !== 'totalTx'){
                            p2pdata.push({
                                quarter: key,
                                fill: randomColor(),
                                earnings: response[key]
                            })
                        }    
                    }
                    setP2pStatusData(p2pdata)
                }
            })
    }, [])

    useEffect(() => {
        api.get('dashboard/statistics')
            .then( response => {
                if(response.error){
                    console.log(response.error)
                }
                else {
                    setProfitData(response)
                }
            })
    }, [])

    useEffect(() => {
        api.get('dashboard/transactions-count')
            .then( response => {
                if(response.error){
                    console.log(response.error)
                }
                else {
                    setTotalTxData(response)
                }
            })
    }, [])


    return (
        <Page pageClass={ styles.home }>

            <div className={ styles.card }>
                <section className={ classNames( styles.bottomRow, styles.grid ) } >
                    {
                        totalTxData && 
                        <div>
                            <h2 className={styles.header}>Total Transactions Volume</h2>
                            <article className={ styles.chartCover }>
                                <TableContainer>
                                    <Table variant={'simple'} colorScheme={'facebook'}>
                                        <Tbody>
                                            <Tr>
                                                <Td fontWeight={'800'}>Time Period</Td>
                                                <Td fontWeight={'500'}>Last Hour</Td>
                                                <Td fontWeight={'500'}>Today</Td>
                                                <Td fontWeight={'500'}>Yesterday</Td>
                                                <Td fontWeight={'500'}>This Week</Td>
                                                <Td fontWeight={'500'}>This Month</Td>
                                            </Tr>
                                            <Tr>
                                                <Td fontWeight={'800'}>Number of transactions</Td>
                                                <Td fontWeight={'300'}>{totalTxData.lastHour}</Td>
                                                <Td fontWeight={'300'}>{totalTxData.daily}</Td>
                                                <Td fontWeight={'300'}>{totalTxData.yesterday}</Td>
                                                <Td fontWeight={'300'}>{totalTxData.week}</Td>
                                                <Td fontWeight={'300'}>{totalTxData.month}</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </article>
                        </div>
                        
                    }
                    {
                        profitData &&
                        <div className={styles.chartHeader}>
                            <h2>Profit And Loss</h2>
                            <article className={ styles.chart } style={{ height: '400px'}}>
                                    <Profit data={profitData} />
                            </article>
                        </div>
                    }
                    <div className={ styles.chartHeader }>
                        <h2>Transaction Status</h2>
                        <article style={{height: '420px'}} className={ styles.chart }>
                            <MyBarChart data={transactionStatusData} labelStyles={{fontSize: '10px'}}/>
                        </article>
                    </div>
                    <div className={ styles.chartHeader }>
                        <h2>Currencies Shared</h2>

                        <article style={{height: '420px'}} className={ classNames( styles.chart, styles.pie ) }>
                            <div className={ styles.keys }>
                                <PieKeys  data={cryptoTransactionsData}/>
                            </div>
                            <MyPie data={cryptoTransactionsData} radius={150}/>
                        </article>
                    </div>
                    <div className={ styles.chartHeader }>
                        <h2>P2P Status</h2>
                        <article style={{height: '420px'}} className={ styles.chart }>
                            <MyBarChart data={p2pStatusData} labelStyles={{fontSize: '10px'}}/>
                        </article>
                    </div>
                    <div className={ styles.chartHeader }>
                        <h2>Transaction Volume</h2>
                        <article className={ styles.chart } style={{height: 'fit-content' }}>
                            <TransactionVolume />
                        </article>
                    </div>
                </section>
            </div>
        </Page>
    );
};

export default Home;
