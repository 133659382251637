import { api } from "../../utils/api";
import { orderConstants } from "../constants";
import { dispatcher } from "./utils";

const getBanks = ( currency ) => async ( dispatch ) =>
{

    if ( !currency )
    {
        dispatch( dispatcher( orderConstants.GET_BANKS_SUCCESS, [] ) );
    }
    else
    {
        dispatch( dispatcher( orderConstants.GET_BANKS_REQUEST ) );

        //https://api.easyshare.africa/api/utils/banks/NGN
        const data = await api.get( `https://api.easyshare.africa/api/utils/banks/${ currency }`, {},
            {
                useFullRoot: true,
                useHeaders: false
            } );

        if ( data.error )
        {
            dispatch( dispatcher( orderConstants.GET_BANKS_FAILURE, data.error ) );
        }
        else
        {
            dispatch( dispatcher( orderConstants.GET_BANKS_SUCCESS, data.data ) );
        }
    }

};

const verifyUser = ( accountNumber, bankCode ) => async ( dispatch ) =>
{
    //https://api.easyshare.africa/api/utils/bank/verifyAccount?accountNumber=9876543266&bankCode=000

    dispatch( dispatcher( orderConstants.VERIFY_USER_REQUEST ) );


    const data = await api.get( "https://api.easyshare.africa/api/utils/bank/verifyAccount",
        {
            accountNumber,
            bankCode
        },
        {
            useFullRoot: true,
            useHeaders: false,
            useQuery: true
        } );

    if ( data.error )
    {
        dispatch( dispatcher( orderConstants.VERIFY_USER_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( orderConstants.VERIFY_USER_SUCCESS, data.account_name ) );
    }
};

const clearUser = () => async ( dispatch ) =>
{
    dispatch( dispatcher( orderConstants.VERIFY_USER_SUCCESS, "" ) );
};

const getCryptoList = () => async ( dispatch ) =>
{
    // https://api.easyshare.africa/api/rates/exchange/crypto -list
    dispatch( dispatcher( orderConstants.GET_CRYPTO_LIST_REQUEST ) );

    //https://api.easyshare.africa/api/utils/banks/NGN
    const data = await api.get( "https://api.easyshare.africa/api/rates/exchange/crypto", {},
        {
            useFullRoot: true,
            useHeaders: false
        } );

    if ( data.error )
    {
        dispatch( dispatcher( orderConstants.GET_CRYPTO_LIST_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( orderConstants.GET_CRYPTO_LIST_SUCCESS, data ) );
    }
};

const createOrder = order => async ( dispatch ) =>
{
    dispatch( dispatcher( orderConstants.CREATE_ORDER_REQUEST ) );
    const data = await api.post( "orders/create", order, {}, false, true );

    if ( data.error )
    {
        dispatch( dispatcher( orderConstants.CREATE_ORDER_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( orderConstants.CREATE_ORDER_SUCCESS ) );
    }
};

const getAllOrders = ( page = 1 ) => async ( dispatch ) =>
{
    dispatch( dispatcher( orderConstants.GETALL_REQUEST ) );

    const data = await api.get( "orders/", { page } );

    if ( data.error )
    {
        dispatch( dispatcher( orderConstants.GETALL_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( orderConstants.GETALL_SUCCESS, data ) );
    }
};

const confirmOrder = ( values ) => async ( dispatch ) =>
{
    dispatch( dispatcher( orderConstants.CONFIRM_ORDER_REQUEST ) );
    const data = await api.post( "orders/create", values, { orderId: values.orderId }, false, true );

    if ( data.error )
    {
        dispatch( dispatcher( orderConstants.CONFIRM_ORDER_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( orderConstants.CONFIRM_ORDER_SUCCESS ) );
    }
};

const payFiat = orderId => async ( dispatch ) =>
{
    dispatch( dispatcher( orderConstants.PAY_FIAT_REQUEST ) );
    const data = await api.post( `orders/payfiat/${ orderId }`, { orderId }, {}, false, true );

    if ( data.error )
    {
        dispatch( dispatcher( orderConstants.PAY_FIAT_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( orderConstants.PAY_FIAT_SUCCESS ) );
    }
};



const reset = () => async ( dispatch ) =>
{
    dispatch( dispatcher( orderConstants.CREATE_ORDER_RESET ) );
};

const resetAll = () => async ( dispatch ) =>
{
    dispatch( dispatcher( orderConstants.ORDERS_RESET ) );
};


export const orderActions =
{
    getBanks,
    verifyUser,
    clearUser,
    getCryptoList,
    createOrder,
    reset,
    resetAll,
    getAllOrders,
    confirmOrder,
    payFiat
};
