import React, { useState } from 'react';
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { authActions, themeActions } from "../../redux/actions";

import {RiKey2Fill, RiLogoutBoxRFill} from 'react-icons/ri'
// import {MdModeEdit} from 'react-icons/md'
import {AiFillCaretDown} from 'react-icons/ai'
import {BsFillQuestionCircleFill} from 'react-icons/bs'

// import styles from './Header.module.scss';
// import darkStyles from './HeaderDark.module.scss';

// import { Link } from 'react-router-dom';
import AlertModal from '../AlertModal';
import { ChangePassword } from './ChangePassword';
import { FaMoon } from 'react-icons/fa';
import { HiSun } from 'react-icons/hi';


function Header () 
{
    const { darkMode } = useSelector( state => state.theme )
    const styles = darkMode ? require("./HeaderDark.module.scss") : require("./Header.module.scss");

    const [ open, setOpen ] = useState( false ); 
    const [ changePassword, setChangePassword ] = useState(false);
    const dispatch = useDispatch();

    const { pendingLogout, user } = useSelector( state => state.auth );

    const signOut = () =>
    {
        dispatch( authActions.logout() );
    };

    const signOutPend = () =>
    {
        dispatch( authActions.logoutStart() );
    };

    const cancelLogout = () =>
    {
        dispatch( authActions.logoutCancel() );
    };

    const toggleTheme = () => {
        dispatch( themeActions.toggle() )
    }

    const themeToggleIconStyle = {
        marginRight: "20px",
        cursor: 'pointer',
        fontSize: '16px',
    }

    return (
        <>
        <div className={ styles.header }>

            {
                darkMode ? 
                    <HiSun 
                        onClick={toggleTheme} 
                        color={'white'} 
                        style={themeToggleIconStyle}
                        title="Switch to light mode"
                    />
                    :
                    <FaMoon 
                        onClick={toggleTheme}
                        style={themeToggleIconStyle}
                        title="Switch to dark mode"
                    />
                
            }
            
            <p className={ styles.name }>Welcome, { user.username }</p>

            <AiFillCaretDown
                className={classNames(styles.icon,styles.drop, {
                    [styles.open] : open
                })}
                onClick={() => setOpen(!open)}
            />
        
            {
            open && <div className={styles.dropDown}>
                    {/* <Link to={ {
                        pathname: `/dashboard/users/edit-user`,
                        state: {
                            row : {
                                email: user.email,
                                id: user.id,
                                role: user.roles,
                                username: user.username,
                            }
                        }
                    } }>
                        <MdModeEdit className={styles.icon} />
                        <span>Edit { user.username }</span> 
                    </Link> */}
                    <p onClick={() => setChangePassword(true)}>
                        <RiKey2Fill className={styles.icon} />
                        <span>Change Password</span>
                    </p>
                    <p onClick={signOutPend}>
                        <RiLogoutBoxRFill className={styles.icon} />
                        <span>Sign out</span>
                    </p>
                </div>
            }
        </div>
            {
                pendingLogout && <AlertModal
                    msg="You are about to signout"
                    btnText="Continue"
                    icon={ <BsFillQuestionCircleFill /> }
                    cancel={ cancelLogout }
                    proceed={ signOut }
                />
            }
            <ChangePassword isOpen={changePassword} onClose={() => setChangePassword(false)}/>
        </>
    );
}

export { Header };
