import { themeColors } from "../colors";
export const Button = {
  baseStyle: {
    //generic style
  },

  //customize variants
  variants: {
    default: {
      width: '170px',
      padding: '10px',
      bg: themeColors.deepGreen,
      borderRadius: '5px',
      color: 'white',
      fontSize: '15px',
      fontWeight: 'bold',
      maxHeight: '48px',
      textAlign: 'center',
      margin: '15px auto'
    }
  }
};