export const Box = {
    baseStyle: {
        //generic style goes here
    },
    variants: {
        formWrapper: {
            alignContent: 'center',
            justifyContent: 'center',
            display: 'flex'
        },
        form: {
            bg: 'teal.800',
            padding: '20px',
            borderRadius: '5px'
        },
        imageFallback: {
            borderRadius: '100%',
            float: 'right'
        }
    }
}