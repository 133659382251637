import { api } from '../../utils/api'
import { customerConstants } from '../constants'
import { dispatcher } from './utils'

const {
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    CUSTOMER_RESET
} = customerConstants

const getAll = ( page = 1 ) => async dispatch => {
    dispatch( dispatcher(GETALL_REQUEST) )

    const data = await api.get('customer', {page} )

    if (data.error) {
        dispatch( dispatcher( GETALL_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( GETALL_SUCCESS, data ) )
    }
}

const reset = () => async dispatch => {
    dispatch( dispatcher( CUSTOMER_RESET ) )
}

export const customerActions = {
    getAll,
    reset
}