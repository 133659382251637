import { Switch, useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { Login } from "./Login";
import { ResetPassword } from "./ResetPassword";


export function LoginRoot(){
  const { path } = useRouteMatch();
  return(
    <Switch>
      <Route exact path={path}>
        <Login />
      </Route>
      <Route exact path={`${path}/reset-password`}>
        <ResetPassword />
      </Route>
    </Switch>
  )
}