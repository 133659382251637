import { api } from '../../utils/api';
import { transfersConstant } from '../constants';
import { dispatcher } from './utils';

const {
  PAGE_SIZE,
  RESET_TRANSACTIONS,
  GETALL_REQUEST_ON_HOLD,
  GETALL_FAILURE_ON_HOLD,
  GETALL_SUCCESS_ON_HOLD,
  GETALL_FAILED,
  GETALL_SUCCESS,
} = transfersConstant;


const changeTxStatus = (payload) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST_ON_HOLD));

  const data = await api.post('transaction/transfer', payload);
  if (data.error) {
    dispatch(dispatcher(GETALL_FAILED, data.error));
  } else {
    dispatch(dispatcher(GETALL_FAILED, data.message));
  }
};

const getAll =
  (page = 1) =>
  async (dispatch) => {
    dispatch(dispatcher(GETALL_REQUEST_ON_HOLD));

    const data = await api.get(
      'transaction/transfers',
      { page, size: PAGE_SIZE },
      { useHeaders: true, useQuery: true },
    );

    if (data.error) {
      dispatch(dispatcher(GETALL_FAILURE_ON_HOLD, data.error));
    } else {
      dispatch(dispatcher(GETALL_SUCCESS_ON_HOLD, data));
    }
  };

const reset = () => async (dispatch) => {
  dispatch(dispatcher(RESET_TRANSACTIONS));
};

export const transferActions = {
  reset,
  getAll,
  changeTxStatus,
};
