import { api } from "../../utils/api";
import { overviewConstants } from "../constants";
import { dispatcher } from "./utils";

const getOpayBalance = () => async ( dispatch ) =>
{
    dispatch( dispatcher( overviewConstants.GET_OPAY_BAL_REQUEST ) );

    const data = await api.get( "dashboard/opay/balance" );

    if ( data.error )
    {
        dispatch( dispatcher( overviewConstants.GET_OPAY_BAL_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( overviewConstants.GET_OPAY_BAL_SUCCESS, data ) );
    }

};

export const overviewActions =
{
    getOpayBalance
};
