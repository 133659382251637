import { api } from "../../utils/api";
import { tradePairConstants } from "../constants";
import { dispatcher } from "./utils";

const getAllTradePairs = ( page = 1 ) => async ( dispatch ) =>
{

    dispatch( dispatcher( tradePairConstants.GETALL_TP_REQUEST ) );

    const data = await api.get( "tradingpairs", {
        page
    }, { useHeaders: true } );

    if ( data.error )
    {
        dispatch( dispatcher( tradePairConstants.GETALL_TP_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( tradePairConstants.GETALL_TP_SUCCESS, data ) );
    }
};

const editTradePair = ( pair ) => async ( dispatch ) =>
{
    dispatch( dispatcher( tradePairConstants.EDIT_TP_REQUEST ) );

    const data = await api.put( `tradingpairs/${ pair.id }`, pair, {}, false, true );

    if ( data.error )
    {
        dispatch( dispatcher( tradePairConstants.EDIT_TP_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( tradePairConstants.EDIT_TP_SUCCESS, pair ) );
    }

};

const createTradePair = ( pair ) => async ( dispatch ) =>
{
    dispatch( dispatcher( tradePairConstants.CREATE_TP_REQUEST ) );

    const data = await api.post( `tradingpairs`, pair, {}, false, true );

    if ( data.error )
    {
        dispatch( dispatcher( tradePairConstants.CREATE_TP_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( tradePairConstants.CREATE_TP_SUCCESS, data ) );
    }

};

const deleteTradePair = ( pair ) => async ( dispatch ) =>
{
    dispatch( dispatcher( tradePairConstants.DELETE_TP_REQUEST ) );

    const data = await api.del( `tradingpairs/${ pair.id }`, {}, {}, false, true );

    if ( data.error )
    {
        dispatch( dispatcher( tradePairConstants.DELETE_TP_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( tradePairConstants.DELETE_TP_SUCCESS, pair ) );
    }


};

const resetDelete = () => async ( dispatch ) =>
{
    dispatch( dispatcher( tradePairConstants.RESET_TP_DELETE ) );
};

const resetCreate = () => async ( dispatch ) =>
{
    dispatch( dispatcher( tradePairConstants.RESET_TP_CREATE ) );
};

const resetEdit = () => async ( dispatch ) =>
{
    dispatch( dispatcher( tradePairConstants.RESET_TP_EDIT ) );
};

const reset = () => async ( dispatch ) =>
{
    dispatch( dispatcher( tradePairConstants.RESET_TP ) );
};


export const tradePairActions =
{
    getAllTradePairs,
    createTradePair,
    editTradePair,
    deleteTradePair,
    reset,
    resetEdit,
    resetDelete,
    resetCreate

};
