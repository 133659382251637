import { useState } from 'react';

const useTable = ( { size = 24, data, totalPages, fetchMore } ) =>
{
    const [ pageSize ] = useState( size );
    const [ currentPage, setCurrentPage ] = useState( 0 );
    const [ fetchedPages, setFP ] = useState( [ 1 ] );

    const changePage = ( page ) =>
    {
        setCurrentPage( page - 1 );

        if ( !fetchedPages.includes( page ) )
        {
            fetchMore( page );
            setFP( pages => [ ...pages, page ] );
        }
    };

    const incPage = () =>
    {
        const p = currentPage + 1;
        if ( p >= totalPages )
        {
            changePage( totalPages );
        }
        else
        {
            changePage( p + 1 );
        }
    };

    const decPage = () =>
    {
        const p = currentPage - 1;
        if ( p < 0 )
        {
            changePage( 0 );
        }
        else
        {
            changePage( p + 1 );
        }
    };


    return {
        pageSize,
        currentPage,
        incPage,
        decPage,
        changePage
    };
};

export { useTable };
