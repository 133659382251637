import React from 'react';
import { Thead, Tr, Td } from '@chakra-ui/react';

// import styles from './Table.module.scss';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

function TableHeader ( { headers } )
{
    const { darkMode } = useSelector( state => state.theme )
    const styles = darkMode ? require("./TableDark.module.scss") : require("./Table.module.scss")
    return (
        <Thead>
            <Tr className={styles['header-wrapper']}>
                { headers.map( ( item, index ) => (
                    <Td key={ index } className={ classnames(styles.header) }>
                        { item }
                    </Td>
                ) ) }
            </Tr>
        </Thead>
    );
}

export default TableHeader;
