import { useState } from 'react';
import { BiKey } from 'react-icons/bi'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Top } from './Login'
import styles from './Login.module.scss'
import { string as yupString } from 'yup';
import { api } from '../../../utils/api';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';
import { AlertModal } from '../../../components';

export function ResetPassword(){
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token')
  
  useEffect(() => {
    if(!token){
      history.push('/login')
    }
  }, [token, history])

  const initialValues = {
    password: {
      value: '',
      touched: false,
      error: "required"
    },
    confirmPassword: {
      value: '',
      touched: false,
      error: "required"
    },
  }
  const [ show, setShow ] = useState( false );
  const [ dto, setDto] = useState(initialValues);
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState('')
  const [ success, setSuccess ] = useState(false)

  const { password, confirmPassword } = dto

  const validationSchema = {
    password: yupString()
      .required()
      .required("New password is required")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/, 'Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character'),

    confirmPassword: yupString()
      .required()
      .required("Must confirm new password")
      .matches(password.value, "Doesn't match password")
  }

  const validate = (name, value) => {
    let error = "";
    try {
          value = validationSchema[name]?.validateSync(value);
    } catch (err) {
        if (err.name === "ValidationError") {
        error = err.errors.join(",");
        }
    }
    return { value, error };
  }

  const handleSubmit = () => {
    setError(false)
    setLoading(true)
    const payload = {
      token,
      password: password.value,
      confirmPassword: confirmPassword.value
    }
    api.post('admin/auth/forget-password/complete', payload)
      .then( response => {
        setLoading(false)
        if(response.error){
          setError(response.error)
        }
        else {
          setSuccess(true)
        }
      })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const { value: val, error } = validate(name, value)
    setDto(() => ({
        ...dto,
        [name]: { value: val, touched: true, error },
    }));
  }
  const togglePassword = ( e ) => setShow( () => !show );

  const isInvalid = () => ( confirmPassword.error || password.error );


  return(
    <div className={ styles.login }>
            <Top />
            {
              success && 
              <AlertModal 
                title={'Success'}
                btnText={'Go to login page'}
                msg={'Password reset successful'}
                proceed={() => history.push('/login')}
              />
            }
                <div className={ styles.formBox }>
                    <h3>Reset Password</h3>
                    <section className={ styles.formWrap }>

                        <div className={ styles.formRow }>
                            <label htmlFor='password'>
                                <BiKey style={{transform:'rotateX(180deg)'}} />
                            </label>
                            <input
                                type={ `${ show ? "text" : "password" }` }
                                name='password'
                                placeholder='New password'
                                required
                                value={ password.value }
                                onChange={ handleChange }
                            />
                            <span className={ styles.toggle } onClick={ togglePassword }>
                                { show ? <FaEye /> : <FaEyeSlash /> }
                            </span>
                            {
                              password.touched && password.error &&
                              <p className={styles['form-error-text']}>{password.error}</p>
                            }
                        </div>
                        <div className={ styles.formRow }>
                            <label htmlFor='confirmPassword'>
                                <BiKey style={{transform:'rotateX(180deg)'}} />
                            </label>
                            <input
                                type={ `${ show ? "text" : "password" }` }
                                name='confirmPassword'
                                placeholder='Confirm new password'
                                required
                                value={ confirmPassword.value }
                                onChange={ handleChange }
                            />
                            <span className={ styles.toggle } onClick={ togglePassword }>
                                { show ? <FaEye /> : <FaEyeSlash /> }
                            </span>
                            {
                              confirmPassword.touched && confirmPassword.error &&
                              <p className={styles['form-error-text']}>{confirmPassword.error}</p>
                            }
                        </div>
                        
                        <button
                            className={ styles.submit }
                            disabled={ loading || isInvalid() }
                            onClick={ handleSubmit }
                        >
                          {
                            error ? 'Retry' :
                            loading ? <Spinner /> : 'Reset'
                          }
                        </button>
                        {
                          error && 
                          <p className={styles['error-text']}>{error}</p>
                        }
                    </section>
                </div>
        </div>

  )
}