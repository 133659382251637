import { orderConstants } from '../constants';

import 
{
    request,
    failure,
    getAllSuccess
} from "./utils";

const initialState =
{

    banks: [],
    bankStatus: "idle",
    accountName: "",
    verifying: "idle",
    verifyErr: null,
    bankErr: null,
    totalPages: 0,
    totalElements: 0,
    first: true,
    last: false,
    number: 0,
    size: 24,
    content: [],
    error: null,
    status: "idle",
    realCount: 0,
    cryptos: [],
    cryptoStatus: "idle",
    cryptoErr: null,
    creatingOrder: "idle",
    creatingOrderErr: null,
    confirmOrder: "idle",
    confirmOrderErr: null,
    payFiatStatus: "idle",
    payFiatErr: null,
};

export function orders ( state = initialState, action )
{
    const { type, payload } = action;
    switch ( type ) 
    {

        case orderConstants.GETALL_REQUEST:
            return request( state );

        case orderConstants.GETALL_FAILURE:
            return failure( state, action.payload );

        case orderConstants.GETALL_SUCCESS:
            return getAllSuccess( state, action.payload );

        case orderConstants.GET_CRYPTO_LIST_REQUEST:
            return {
                ...state,
                cryptoStatus: "pending",
                cryptoErr: null
            };

        case orderConstants.GET_CRYPTO_LIST_FAILURE:
            return {
                ...state,
                cryptoStatus: "rejected",
                cryptoErr: payload
            };

        case orderConstants.GET_CRYPTO_LIST_SUCCESS:
            return {
                ...state,
                cryptoStatus: "resolved",
                cryptoErr: null,
                cryptos: payload,
            };


        case orderConstants.GET_BANKS_REQUEST:
            return {
                ...state,
                bankStatus: "pending",
                bankErr: null,
            };

        case orderConstants.GET_BANKS_FAILURE:
            return {
                ...state,
                bankStatus: "rejected",
                bankErr: payload
            };

        case orderConstants.GET_BANKS_SUCCESS:
            return {
                ...state,
                banks: payload,
                bankErr: null,
                bankStatus: "resolved"
            };

        case orderConstants.VERIFY_USER_REQUEST:
            return {
                ...state,
                verifying: "pending",
                verifyErr: null,
            };

        case orderConstants.VERIFY_USER_FAILURE:
            return {
                ...state,
                verifying: "rejected",
                verifyErr: payload
            };

        case orderConstants.VERIFY_USER_SUCCESS:
            return {
                ...state,
                verifying: "resolved",
                accountName: payload,
                verifyErr: null,
            };

        case orderConstants.CREATE_ORDER_REQUEST:
            return {
                ...state,
                creatingOrder: "pending",
                creatingOrderErr: null
            };

        case orderConstants.CREATE_ORDER_FAILURE:
            return {
                ...state,
                creatingOrder: "rejected",
                creatingOrderErr: payload
            };

        case orderConstants.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                creatingOrder: "resolved",
                creatingOrderErr: null
            };

        case orderConstants.CREATE_ORDER_RESET:
            return {
                ...state,
                creatingOrder: "reset",
                creatingOrderErr: null,
                accountName: "",

            };

        case orderConstants.CONFIRM_ORDER_RESET:
            return {
                ...state,
                confirmOrder: "reset",
                confirmOrderErr: null,
            };

        case orderConstants.CONFIRM_ORDER_FAILURE:
            return {
                ...state,
                creatingOrder: "rejected",
                creatingOrderErr: payload
            };

        case orderConstants.CONFIRM_ORDER_SUCCESS:
            return {
                ...state,
                creatingOrder: "resolved",
                creatingOrderErr: null
            };

        case orderConstants.PAY_FIAT_REQUEST:
            return {
                ...state,
                payFiatErr: null,
                payFiatStatus: "pending"
            };

        case orderConstants.PAY_FIAT_FAILURE:
            return {
                ...state,
                payFiatErr: payload,
                payFiatStatus: "rejected"
            };

        case orderConstants.PAY_FIAT_SUCCESS:
            return {
                ...state,
                payFiatErr: null,
                payFiatStatus: "resolved"
            };




        case orderConstants.ORDERS_RESET:
            return initialState;


        default:
            return state;
    }
}
