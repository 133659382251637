export const Roles = [
    {
        name:'user',
        value:'ROLE_USER'
    },
    {
        name:'admin',
        value:'ROLE_ADMIN'
    },
    {
        name:'super admin',
        value:'ROLE_SUPERADMIN'
    },
]

export const rolesConstants = {
    GETALL_REQUEST: 'ROLES_GETALL_REQUEST',
    GETALL_SUCCESS: 'ROLES_GETALL_SUCCESS',
    GETALL_FAILURE: 'ROLES_GETALL_FAILURE',

    CREATE_REQUEST: 'ROLES_CREATE_REQUEST',
    CREATE_SUCCESS: 'ROLES_CREATE_SUCCESS',
    CREATE_FAILURE: 'ROLES_CREATE_FAILURE',
    
    UPDATE_REQUEST: 'ROLES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ROLES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ROLES_UPDATE_FAILURE',

    DELETE_REQUEST: 'ROLES_DELETE_REQUEST',
    DELETE_SUCCESS: 'ROLES_DELETE_SUCCESS',
    DELETE_FAILURE: 'ROLES_DELETE_FAILURE',

    RESET_ROLES: "RESET_ROLES",

    GETALL_PERMISSIONS: 'PERMISSIONS_GETALL',
}

export const defaultPermissions = [
    {
      id: 'ManageAll',
      description: 'Has the ability to do all actions. Extra text for demo purposes'
    },
    {
      id: 'ViewDashboard',
      description: 'Can View Dashboard'
    },
    {
      id: 'ViewUsers',
      description: 'Can View Users'
    },
    {
      id: 'CreateUser',
      description: 'Can Create a User'
    },
    {
      id: 'EditUser',
      description: 'Can Edit a User'
    },
    {
      id: 'UpdateUser',
      description: 'Can Update a User'
    },
    {
      id: 'DeleteUser',
      description: 'Can Delete a User'
    },
]