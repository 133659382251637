import { BsArrowLeft } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import styles from './style.module.scss'

export default function BackButton(){
  const history = useHistory();

  return(
    <BsArrowLeft 
      onClick={() => history.goBack()}
      className={styles.backbutton}
    />
  )
}