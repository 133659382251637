export const authConstants =
{
    LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
    LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
    LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",

    LOGOUT_USER_REQUEST: "LOGOUT_USER_REQUEST",
    LOGOUT_USER_SUCESS: "LOGOUT_USER_SUCESS",
    LOGOUT_USER_CANCEL: "LOGOUT_USERCANCEL",

};
