import {api} from '../../utils/api'
import {affiliateConstants} from '../constants'
import { dispatcher } from "./utils";

const getAllAffiliates = ( page = 1 ) => async dispatch => {
    dispatch( dispatcher( affiliateConstants.GETALL_REQUEST ) )

    const data = await api.get( 'affiliate/', { page } )

    if ( data.error ) {
        dispatch( dispatcher( affiliateConstants.GETALL_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( affiliateConstants.GETALL_SUCCESS, data ) )
    }
}

const reset = () => async dispatch => {
    dispatch( dispatcher( affiliateConstants.AFFILIATES_RESET ) )
}

export const affiliateActions =  {
    getAllAffiliates,
    reset,
}
