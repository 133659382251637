import { overviewConstants } from '../constants';


const initialState =
{
    getBalErr: null,
    balStatus: "idle",
    content: {}

};

export function overview ( state = initialState, action )
{
    const { type, payload } = action;
    switch ( type ) 
    {

        case overviewConstants.GET_OPAY_BAL_REQUEST:
            return {
                ...state,
                getBalErr: null,
                balStatus: "pending"
            };

        case overviewConstants.GET_OPAY_BAL_FAILURE:
            return {
                ...state,
                getBalErr: payload,
                balStatus: "rejected"
            };

        case overviewConstants.GET_OPAY_BAL_SUCCESS:
            return {
                ...state,
                getBalErr: null,
                balStatus: "resolved",
                content: payload
            };

        default:
            return state;
    }
}
