import { api } from "../../utils/api";
import { authConstants } from "../constants";
import { dispatcher } from "./utils";

const login = ( email, password ) => async ( dispatch ) =>
{
    const body = { email, password };

    dispatch( dispatcher( authConstants.LOGIN_USER_REQUEST ) );

    let data = await api.post( "admin/auth/login", body, {}, null, false );

    if(data.error){
        dispatch( dispatcher( authConstants.LOGIN_USER_FAILURE, data.error ) );
    }
    else
    {
        const { permissions } = data.payload
        const permissionNames = []
        for(let permission of permissions){
            permissionNames.push(permission.name)
        }
        data.payload.permissions = permissionNames
        localStorage.setItem( "user", JSON.stringify( data ) );
        dispatch( dispatcher( authConstants.LOGIN_USER_SUCCESS, data ) );
        
    }

};


const setUser = ( data ) => async ( dispatch ) =>
{
    dispatch( dispatcher( authConstants.LOGIN_USER_SUCCESS, data ) );
};

const logoutStart = () => async ( dispatch ) =>
{
    dispatch( dispatcher( authConstants.LOGOUT_USER_REQUEST ) );
};

const logoutCancel = () => async ( dispatch ) =>
{
    dispatch( dispatcher( authConstants.LOGOUT_USER_CANCEL ) );
};

const logout = () => async ( dispatch ) =>
{
    localStorage.removeItem( 'user' );
    dispatch( dispatcher( authConstants.LOGOUT_USER_SUCESS ) );
};

export const authActions =
{
    login,
    logout,
    logoutStart,
    logoutCancel,
    setUser
};
