import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './Login.module.scss';
import { Link, Redirect } from 'react-router-dom';

import logo from '../../../assets/images/logo.svg';
import AlertModal from '../../../components/AlertModal';

import { FaUserAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import { BiKey } from 'react-icons/bi';
import { ForgotPassword } from './ForgotPassword';
import { authActions } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

export const Top = () =>
{
    const [ scrolling, isScrolling ] = useState( false );

    const scroll = () =>
    {
        if ( window.scrollY > 15 )
        {
            isScrolling( true );
        }
        else
        {
            isScrolling( false );
        }
    };
    useEffect( () =>
    {
        window.addEventListener( 'scroll', scroll );
        return () =>
        {
            window.removeEventListener( 'scroll', scroll );
        };
    }, [] );

    return (
        <div className={ classNames( styles.authTop, {
            [ styles.scroll ]: scrolling
        } ) }>
            <Link to='/'>
                <img src={ logo } alt="easyshare logo" />
            </Link>

            <Link to='#'>
                <p>need help?</p>
            </Link>
        </div>
    );
};

function Login ()
{
    const [ loginDetails, setLoginDetails ] = useState( {
        email: '',
        password: ''
    } );

    const [ checked, setChecked ] = useState( false );
    const [ show, setShow ] = useState( false );
    const [ forgotPassword, setForgotPassword ] = useState(false);

    const [ serverError, setServerError ] = useState( "" );

    const { loading, error, user } = useSelector( state => state.auth );
    const dispatch = useDispatch();

    const { email, password } = loginDetails;

    const onChange = ( e ) => setLoginDetails( () => ( { ...loginDetails, [ e.target.name ]: e.target.value } ) );

    const togglePassword = ( e ) => setShow( () => !show );

    const isValid = () => ( email && password );

    const handleSubmit = ( e ) =>
    {
        e.preventDefault();

        try
        {
            setServerError( '' );
            dispatch( authActions.login( email, password ) );
        } catch ( err )
        {
            setServerError( err );
        }
    };

    if ( user.accessToken )
    {
        return <Redirect to="/dashboard" />;
    }

    return (
        <div className={ styles.login }>
            { error && 
                <AlertModal
                    msg='Invalid Username or Password'
                    btnText='Okay'
                    proceed={() => {
                        dispatch(authActions.logout());
                    }}
                /> }
            <Top />
            {
                forgotPassword ?
                <ForgotPassword goBack={() => setForgotPassword(false)}/>
                :
                <div className={ styles.formBox }>
                    <h3>Welcome</h3>
                    <section className={ styles.formWrap }>

                        { serverError && (
                            <span
                                className={ styles.error }
                                style={ { textAlign: "center", display: "block" } }>
                                { serverError }
                            </span>
                        ) }

                        <div className={ styles.formRow }>
                            <label htmlFor='username'>
                                <FaUserAlt />
                            </label>
                            <input
                                type='text'
                                name='email'
                                id='email'
                                placeholder='email'
                                required
                                value={ email }
                                onChange={ onChange }
                            />
                        </div>

                        <div className={ styles.formRow }>
                            <label htmlFor='password'>
                                <BiKey style={{transform:'rotateX(180deg)'}} />
                            </label>
                            <input
                                type={ `${ show ? "text" : "password" }` }
                                name='password'
                                id='password'
                                placeholder='password'
                                required
                                value={ password }
                                onChange={ onChange }
                            />
                            <span className={ styles.toggle } onClick={ togglePassword }>
                                { show ? <FaEye /> : <FaEyeSlash /> }
                            </span>
                        </div>

                        <label className={ styles.remember }>
                            Remember Me
                            <input className={ styles.checkBox } type="checkbox" name="remember" id="remember" checked={ checked } onChange={ () => setChecked( !checked ) } />
                            
                        </label>
                        
                        <button
                            className={ styles.submit }
                            disabled={ loading || !isValid() }
                            onClick={ handleSubmit }
                        >Login</button>

                        <p onClick={() => setForgotPassword(true)} className={styles['forgot-password']}>Forgot password?</p>
                    </section>
                </div>
            
            }
        </div>
    );
}

export { Login };
