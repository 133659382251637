import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

import styles from './Input.module.scss';


//Choose country
function Select ( props ) 
{
    const {
        label,
        id,
        readOnly,
        placeholder = "Choose",
        options
    } = props;
    const p = { ...props };
    delete p.options;
    const [ field /*, meta, helpers*/ ] = useField( p );
    return (
        <div className={ styles.fieldGroup }>
            <label
                className={ styles.label }
                htmlFor={ id }>{ label }
            </label>
            <select id={ id }
                { ...field }
                { ...p }
                className={ classNames( styles.select,
                    { [ styles.disabled ]: readOnly } ) }>
                <option value="">{ placeholder }</option>
                {
                    options.map( o => (
                        <option key={ o.id || o.value } value={ o.value }>{ o.name }</option>
                    ) )
                }
            </select>

        </div>

    );
}

export { Select };
