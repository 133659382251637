import {
  Grid,
  Heading,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Page, Loader, Retry } from '../../../components';
import { currencyActions, rootwalletActions } from '../../../redux/actions';
import { Wallet } from './WalletComponent';

const ExternalWallet = () => {
  const { darkMode } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const { content, status, loading, error } = useSelector(
    (state) => state.externalWalletConfig,
  );

  const objectParser = (obj) => {
    if (obj.constructor.name === 'Object') {
      return true
    } else {
      return false
    }
  }

  const { content: currencyContent, status: currencyStatus } = useSelector(state => state.currencies)


  useEffect(() => {
    if (status === 'idle') {
      dispatch(rootwalletActions.getAll());
      dispatch(currencyActions.getAll())
    }
  }, [dispatch, status]);

  return (
    <Page colorScheme={darkMode ? 'teal' : 'blackAlpha'}>
      <Heading as="h3" textAlign={'center'} marginBottom={'1rem'}>
        EXTERNAL WALLET BALANCES
      </Heading>
      { status === 'pending' && loading && <Loader />}
      {/* rejected */}
      {status === "rejected" && <Retry message={error} callback={() => dispatch(rootwalletActions.getAll())} />}
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {status === 'resolved' && <Wallet
          fiat={content.fiat}
          crypto={content.crypto}
          currency={currencyContent}
          currencyStatus={currencyStatus}
        />}
      </Grid>
    </Page>
  );
};

export default ExternalWallet;
