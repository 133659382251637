import
{
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { ScrollToTop } from "./components";

import
{
  LoginRoot,
  Root as Dashboard,
  NotFound
} from "./pages";


function App ()
{
  return (
    <Switch>
      <Route path="/login">
        <ScrollToTop>
          <LoginRoot />
        </ScrollToTop>
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route exact path='*'>
        <ScrollToTop>
          <NotFound />
        </ScrollToTop>
      </Route>

    </Switch>
  );
}

export default App;
