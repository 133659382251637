import { api } from '../../utils/api'
import { referralCommissionConstants } from '../constants'
import { dispatcher } from './utils'

const {
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    CREATE_REQUEST,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILURE,
    RESET_REFERRAL_COMMISSION
} = referralCommissionConstants

const getAll = () => async dispatch => {
    dispatch( dispatcher( GETALL_REQUEST ) )

    const data = await api.get( 'referral-config' , { }, { useHeaders: true, useQuery: true} )

    if (data.error) {
        dispatch( dispatcher( GETALL_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( GETALL_SUCCESS, data ) )
    }
}

const createReferralCommission = referralCommission => async dispatch => {
    dispatch( dispatcher( CREATE_REQUEST ) )

    const data = await api.post( 'referral-config', referralCommission )

    if (data.error) {
        dispatch( dispatcher( CREATE_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( CREATE_SUCCESS, data ) )
    }
}

const editReferralCommission = referralCommission => async dispatch => {
    dispatch( dispatcher( UPDATE_REQUEST ) )

    const data = await api.put( 'referral-config', referralCommission )

    if (data.error) {
        dispatch( dispatcher( UPDATE_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( UPDATE_SUCCESS, data ) )
    }
}

const deleteReferralCommission = id => async dispatch => {
    dispatch( dispatcher( DELETE_REQUEST ) )

    const data = await api.del(`referral-config/${id}`)

    if (data.error) {
        dispatch( dispatcher( DELETE_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( DELETE_SUCCESS, data ) )
    }
}

const reset = () => async dispatch => {
    dispatch( dispatcher( RESET_REFERRAL_COMMISSION ) )
}

export const referralCommissionActions =
{
    getAll,
    editReferralCommission,
    createReferralCommission,
    deleteReferralCommission,
    reset,
};