import { Box, Heading, Select, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTable } from "../../../hooks";
import { countriesActions, referralStatisticsActions } from "../../../redux/actions";
import { formatCurrency, nullValue } from "../../../utils/helpers";
import { Loader } from "../../../components/Loader/Loader";
import { NoData } from "../../../components/NoData/NoData";
import { Retry } from "../../../components/Retry/Retry";
import { Table } from "../../../components/Table/Table";
import { TableExt } from "../../../components/Table/TableExt";
import styles from './index.module.scss';
import { ViewReferrals } from "./ViewReferrals";
import { Page, Search } from "../../../components";
import { BiFilter } from "react-icons/bi";
import { FaSort } from "react-icons/fa";


export default function ReferralStatistics() {
  const [activeRow, setActiveRow] = useState({ referredUsers: [], username: '' });
  const [viewReferred, setViewReferred] = useState(false);

  const dispatch = useDispatch();
  const {
    content,
    status,
    totalPages,
    totalElements,
    error,
    size
  } = useSelector(state => state.referralStatistics);

  const fetchMore = (page) => {
    dispatch(referralStatisticsActions.getAll(page));
  };
  const { darkMode } = useSelector(state => state.theme);


  const {
    currentPage,
    incPage,
    decPage,
    changePage
  } = useTable({ content, totalPages, fetchMore, size });

  useEffect(() => {
    if (status === 'idle') dispatch(referralStatisticsActions.getAll());
    if (countryConfig.status === 'idle') {
      dispatch(countriesActions.getAll());
    }
  });
  const [argument, setArgumentValue] = useState({});

  const countryConfig
    = useSelector(state => state.countryConfig);
  const headers = [
    's/n',
    'Username',
    'Currency',
    'Balance',
    'Total Referred',
    ''
  ];
  const [filterCountryValue, setFilterCountryValue] = useState('');
  const [paramPlaceHolder, setPH] = useState('email');
  const [searchParameter, setSearchParameter] = useState("email");
  const [searched, setSearched] = useState(false);
  const [term, setTerm] = useState("");
  const [sortBy, setSortBy] = useState('')
  const [sortDir, setSortDir] = useState('')


  const params = {
    email: 'email',
    username: 'username',
    referralCode: 'referralCode',
  };

  const filter = (e) => {
    let { value } = e.target;
    setTerm(value);
  };

  const search = (e) => {
    if (term) {
      dispatch(referralStatisticsActions.searchReferrals(searchParameter, term.trim()));
      setSearched(true);
    }
  };

  const filterCountry = e => {
    const { value } = e.target;
    setFilterCountryValue(value);


    if (value) {
      argument['filterCountry'] = value;
      dispatch(referralStatisticsActions.filter(1, argument));
    } else {
      delete argument['filterCountry'];
      dispatch(referralStatisticsActions.getAll());
    }
  };

  const sortByAction = e => {
    const { value } = e.target;
    setSortBy(value);
    if (value) {
      argument['sortby'] = value;
      dispatch(referralStatisticsActions.filter(1, argument));
    } else {
      delete argument['sortby'];
      dispatch(referralStatisticsActions.getAll(1, value));
    }
  }

  const sortingDir = e => {
    const { value } = e.target;
    setSortDir(value);
    if (value) {
      argument['dir'] = value;
      dispatch(referralStatisticsActions.filter(1, argument));
    } else {
      delete argument['dir'];
      dispatch(referralStatisticsActions.getAll(1, value));
    }
  }

  const cancelSearch = (e) => {
    setSearched(false);
    setTerm("");
    dispatch(referralStatisticsActions.getAll());
  };

  const searchParamChange = (e) => {
    const { value } = e.target;
    setPH(value);
    setSearchParameter(params[value]);
  };

  const sortValues = {
    username: 'username',
    balance: 'balance',
    referredUsers: 'referred'
  }

  const columns = [
    {
      key: 'username',
      render: (cell, row, index) => {
        return cell ? cell : nullValue();
      }
    },
    {
      key: 'defaultCurrency',
      render: (cell, row, index) => {
        return cell;
      }
    },
    {
      key: 'balance',
      render: (cell, row, index) => {
        return formatCurrency(cell);
      }
    },
    {
      key: 'referred',
      render: (cell, row, index) => {
        return cell;
      }
    },
    {
      key: 'referredUsers',
      render: (cell, row, index) => {
        const handleClick = () => {
          setActiveRow(row);
          setViewReferred(true);
        };
        return (
          <p className={styles['link']} onClick={handleClick}>View Referred Users</p>
        );
      }
    }
  ];

  const tableData = content[currentPage + 1] || [];

  return (
    <Page className={styles['wrapper']}>
      <Heading variant={'centered'}>Referrals</Heading>

      <ViewReferrals isOpen={viewReferred} close={() => setViewReferred(false)} data={activeRow.referredUsers} username={activeRow.username} />
      <Search
        parameters={[...Object.keys(params)]}
        parameterChange={searchParamChange}
        placeholder={"Search for " + paramPlaceHolder}
        changeHandler={filter}
        value={term}
        onSearchClick={search}
        cancel={cancelSearch}
        showCancel={searched}
      />

      
      
      <Box
        marginBottom={'20px'}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Box alignItems={'center'} marginBottom='4' width={'290px'} display={'flex'} >
          <Text
            color={darkMode ? 'gray' : 'black'}
            marginRight={'20px'}
            display={'flex'}
            gap={'5px'}
            alignItems={'center'}
          >
            <BiFilter size={'20px'} />
            Country
          </Text>
          <Select
            fontSize={'14px'}
            height={'25px'}
            width={'150px'}
            borderRadius={'3px'}
            border={darkMode ? '1px solid rgb(128, 128, 128, 0.1)' : '1px solid gray'}
            fontFamily={'sans-serif'}
            // backgroundColor={darkMode ? 'gray' : '#303030;'}
            backgroundColor={'transparent'}
            // color={darkMode ? 'black' : '#303030;'}
            color={'gray'}
            textTransform={'capitalize'}
            value={filterCountryValue}
            onChange={filterCountry}
          >
            <option value={''}>----</option>
            {countryConfig && countryConfig.content.map(i => (
              <option key={i.code} value={i.code}>{i.name}</option>
            ))}
          </Select>
        </Box>
        <Box alignItems={'center'} width={'390px'} display={'flex'}>
          <Text
            color={darkMode ? 'gray' : 'black'}
            marginRight={'10px'}
            display={'flex'}
            gap={'5px'}
            alignItems={'center'}
          >
            <FaSort size={'20px'} />
            Sort By
          </Text>
          <Select
            fontSize={'14px'}
            height={'25px'}
            width={'150px'}
            borderRadius={'3px'}
            border={darkMode ? '1px solid rgb(128, 128, 128, 0.1)' : '1px solid gray'}
            fontFamily={'sans-serif'}
            // backgroundColor={darkMode ? 'gray' : '#303030;'}
            backgroundColor={'transparent'}
            // color={darkMode ? 'black' : '#303030;'}
            color={'gray'}
            textTransform={'capitalize'}
            value={sortBy}
            onChange={sortByAction}
          >
            <option value={''}>----</option>
            {Object.values(sortValues).map(i => (
              <option key={sortValues[i]} value={sortValues[i]}>{i}</option>
            ))}
          </Select>
          <Text
            color={darkMode ? 'gray' : 'black'}
            marginLeft={'20px'}
            display={'flex'}
            gap={'5px'}
            alignItems={'center'}
          >
            Sort Dir
          </Text>
          <Select
            fontSize={'14px'}
            height={'25px'}
            width={'100PX'}
            marginLeft={''}
            marginRight={'2rem'}
            borderRadius={'3px'}
            border={darkMode ? '1px solid rgb(128, 128, 128, 0.1)' : '1px solid gray'}
            fontFamily={'sans-serif'}
            // backgroundColor={darkMode ? 'gray' : '#303030;'}
            backgroundColor={'transparent'}
            // color={darkMode ? 'black' : '#303030;'}
            color={'gray'}
            textTransform={'capitalize'}
            value={sortDir}
            onChange={sortingDir}
          >
            <option value={''}>----</option>
            <option value={'DESC'}>DESC</option>
            <option value={'ASC'}>ASC</option>
          </Select>
        </Box>
      </Box>
      {/*resolved, and no data  */}
      {status === "resolved" && !tableData.length ? <NoData /> : null}


      {/* rejected */}
      {
        status === "rejected" ? <Retry message={error} callback={() => fetchMore(currentPage + 1)} /> :

          status === 'pending' ? <Loader /> :
            tableData.length ?
              <>
                <Table
                  headers={headers}
                  columns={columns}
                  data={tableData}
                  rowClickHandler={() => { }}
                  size={size}
                  currentPage={currentPage}
                />

                <TableExt
                  size={size}
                  total={totalElements}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  goTo={changePage}
                  goNext={incPage}
                  goBack={decPage}
                />
              </>
              :
              null
      }

    </Page>
  );
}