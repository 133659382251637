import { api } from '../../utils/api';
import { tradesConstant } from '../constants';
import { dispatcher } from './utils';

const {
  PAGE_SIZE,
  RESET_TRANSACTIONS,
  GETALL_REQUEST_ON_HOLD,
  GETALL_FAILURE_ON_HOLD,
  GETALL_SUCCESS_ON_HOLD,
  GETALL_FAILED,
  GETALL_SUCCESS,
} = tradesConstant;


const changeTxStatus = (payload) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST_ON_HOLD));

  const data = await api.post('transaction/trade', payload);
  if (data.error) {
    dispatch(dispatcher(GETALL_FAILED, data.error));
  } else {
    console.log(data)
    dispatch(dispatcher(GETALL_FAILED, data.message));
  }
};

const getAllTransactionsOnHold =
  (page = 1) =>
  async (dispatch) => {
    dispatch(dispatcher(GETALL_REQUEST_ON_HOLD));

    const data = await api.get(
      'transaction/trades-transactions',
      { page, size: PAGE_SIZE },
      { useHeaders: true, useQuery: true },
    );

    if (data.error) {
      dispatch(dispatcher(GETALL_FAILURE_ON_HOLD, data.error));
    } else {
      dispatch(dispatcher(GETALL_SUCCESS_ON_HOLD, data));
    }
  };

const reset = () => async (dispatch) => {
  dispatch(dispatcher(RESET_TRANSACTIONS));
};

export const tradeActions = {
  reset,
  getAllTransactionsOnHold,
  changeTxStatus,
};
