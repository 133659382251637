export const kycLimitConstants = {
    GETALL_REQUEST: 'KYC_LIMIT_GETALL_REQUEST',
    GETALL_SUCCESS: 'KYC_LIMIT_GETALL_SUCCESS',
    GETALL_FAILURE: 'KYC_LIMIT_GETALL_FAILURE',
  
    CREATE_REQUEST: 'KYC_LIMIT_CREATE_REQUEST',
    CREATE_SUCCESS: 'KYC_LIMIT_CREATE_SUCCESS',
    CREATE_FAILURE: 'KYC_LIMIT_CREATE_FAILURE',
    
    UPDATE_REQUEST: 'KYC_LIMIT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'KYC_LIMIT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'KYC_LIMIT_UPDATE_FAILURE',
  
    DELETE_REQUEST: 'KYC_LIMIT_DELETE_REQUEST',
    DELETE_SUCCESS: 'KYC_LIMIT_DELETE_SUCCESS',
    DELETE_FAILURE: 'KYC_LIMIT_DELETE_FAILURE',
  
    RESET_KYC_LIMIT: "RESET_KYC_LIMIT"
  }

export const KYC_TIERS = {
    TIER_1: 'TIER 1',
    TIER_2: 'TIER 2',
    TIER_3: 'TIER 3',
  }