import { api } from '../../utils/api';
import { merchantTxConstants } from '../constants';
import { dispatcher } from './utils';

const {
  GETALL_REQUEST,
  GETALL_FAILURE,
  GETALL_SUCCESS,
  PAGE_SIZE,
  RESET_TRANSACTIONS,
} = merchantTxConstants;

const get =
  (page = 1) =>
  async (dispatch) => {
    dispatch(dispatcher(GETALL_REQUEST));

    const data = await api.get(
      'p2p',
      { page, size: PAGE_SIZE },
      { useHeaders: true, useQuery: true },
    );

    if (data.error) {
      dispatch(dispatcher(GETALL_FAILURE, data.error));
    } else {
      dispatch(dispatcher(GETALL_SUCCESS, data));
    }
  };

const filter =
  (page = 1, filters) =>
  async (dispatch) => {
    dispatch(dispatcher(GETALL_REQUEST));
    const argument = { page, ...filters, size: PAGE_SIZE };

    const data = await api.get('p2p', argument, {
      useHeaders: true,
      useQuery: true,
    });
    if (data.error) {
      dispatch(dispatcher(GETALL_FAILURE, data.error));
    } else {
      dispatch(dispatcher(GETALL_SUCCESS, data));
    }
  };

const checkTxFee = (id) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));
};

const searchTransaction = (searchType, searchValue) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.get(
    'transaction/disputes',
    { searchType, searchValue },
    { useHeaders: true, useQuery: true },
  );

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};
const reset = () => async (dispatch) => {
  dispatch(dispatcher(RESET_TRANSACTIONS));
};

const closeDispute = (disputeId, comment) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.post(
    'disputes/close',
    { disputeId, comment },
    { useHeaders: true },
  );

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};

const resolveDispute = (disputeId, resolution, comment) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.post(
    'disputes/resolve',
    { disputeId, resolution, comment },
    { useHeaders: true },
  );

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};

export const merchantTxActions = {
  get,
  searchTransaction,
  reset,
  checkTxFee,
  filter,
  closeDispute,
  resolveDispute,
};
