export const orderConstants =
{
    GETALL_REQUEST: "GETALL_ORDERS_REQUESTS",
    GETALL_SUCCESS: "GETALL_ORDERS_SUCCESS",
    GETALL_FAILURE: "GETALL_ORDERS_FAILURE",

    GET_BY_ID_REQUEST: "GET_ORDER_BY_ID_REQUEST",
    GET_BY_ID_SUCCESS: "GET_ORDER_BY_ID_SUCCESS",
    GET_BY_ID_FAILURE: "GET_ORDER_BY_ID_FAILURE",

    GET_BANKS_REQUEST: "GET_BANKS_REQUEST",
    GET_BANKS_SUCCESS: "GET_BANKS_SUCCESS",
    GET_BANKS_FAILURE: "GET_BANKS_FAILURE",

    VERIFY_USER_REQUEST: "VERIFY_USER_REQUEST",
    VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
    VERIFY_USER_FAILURE: "VERIFY_USER_FAILURE",

    GET_CRYPTO_LIST_REQUEST: "GET_CRYPTO_LIST_REQUEST",
    GET_CRYPTO_LIST_SUCCESS: "GET_CRYPTO_LIST_SUCCESS",
    GET_CRYPTO_LIST_FAILURE: "GET_CRYPTO_LIST_FAILURE",

    
    PAY_FIAT_REQUEST: "PAY_FIAT_REQUEST",
    PAY_FIAT_SUCCESS: "PAY_FIAT_SUCCESS",
    PAY_FIAT_FAILURE: "PAY_FIAT_FAILURE",

    CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
    CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
    CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",

    CONFIRM_ORDER_REQUEST: "CONFIRM_ORDER_REQUEST",
    CONFIRM_ORDER_SUCCESS: "CONFIRM_ORDER_SUCCESS",
    CONFIRM_ORDER_FAILURE: "CONFIRM_ORDER_FAILURE",

    CREATE_ORDER_RESET: "CREATE_ORDER_RESET",
    ORDERS_RESET: "ORDERS_RESET"
};
