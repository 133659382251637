import { api } from '../../utils/api'
import { shareRequestConstants } from '../constants'
import { dispatcher } from './utils'

const {
    GETALL_REQUEST,
    GETALL_FAILURE,
    GETALL_SUCCESS,
    SHARE_REQUEST_RESET,
} = shareRequestConstants

export const getAll = ( page = 1 ) => async dispatch => {
    dispatch( dispatcher(GETALL_REQUEST) )

    const data = await api.get('sharecart', {page} )

    if (data.error) {
        dispatch( dispatcher( GETALL_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( GETALL_SUCCESS, data ) )
    }
}

const reset = () => async dispatch => {
    dispatch( dispatcher( SHARE_REQUEST_RESET ) )
}

export const shareReqActions = {
    getAll,
    reset
}