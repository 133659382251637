export const tradePairConstants =
{
    GETALL_TP_SUCCESS: "GETALL_TP_SUCCESS",
    GETALL_TP_FAILURE: "GETALL_TP_FAILURE",
    GETALL_TP_REQUEST: "GETALL_TP_REQUEST",

    EDIT_TP_SUCCESS: "EDIT_TP_SUCCESS",
    EDIT_TP_FAILURE: "EDIT_TP_FAILURE",
    EDIT_TP_REQUEST: "EDIT_TP_REQUEST",

    DELETE_TP_SUCCESS: "DELETE_TP_SUCCESS",
    DELETE_TP_FAILURE: "DELETE_TP_FAILURE",
    DELETE_TP_REQUEST: "DELETE_TP_REQUEST",

    CREATE_TP_SUCCESS: "CREATE_TP_SUCCESS",
    CREATE_TP_FAILURE: "CREATE_TP_FAILURE",
    CREATE_TP_REQUEST: "CREATE_TP_REQUEST",

    RESET_TP: "RESET_TP",
    RESET_TP_EDIT: "RESET_TP_EDIT",
    RESET_TP_DELETE: "RESET_TP_DELETE",
    RESET_TP_CREATE: "RESET_TP_CREATE"

};
