import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

import styles from './Input.module.scss';


function TextArea ( props ) 
{
    const {
        label,
        id,
        placeholder,
        readOnly
    } = props;
    const [ field, meta/*, helpers */ ] = useField( props );
    return (
        <div className={ styles.fieldGroup }>
            <label
                className={ styles.label }
                htmlFor={ id }>{ label }</label>
            <textarea
                className={ classNames( styles.text,
                    {
                        [ styles.disabled ]: readOnly
                    } ) }
                placeholder={ placeholder }
                { ...field }
                { ...props }
                cols="30" rows="10"></textarea>

            { meta.touched && meta.error ? (
                <small className={ styles.error }>{ meta.error }</small>
            ) : null }

        </div>

    );
}

export { TextArea };
