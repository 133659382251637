import React from 'react';
import 
{
    PieChart,
    Pie,
    Tooltip,
    ResponsiveContainer,
    Cell
} from 'recharts';

import styles from './Charts.module.scss';

const defaults =
{
    data: [
        { x: "$TOKEN", y: 20, fill: "#5BA3CA" },
        { x: "BNB", y: 15, fill: "#B6E1F880" },
        { x: "BTC", y: 65, fill: "#85CEF4" }
    ],
    nameKey: "x",
    formatter: ( value ) => `${ value }%`

};

const MyPie = ({ data = defaults.data, radius = 90, nameKey = defaults.nameKey, toolTipFormat = defaults.formatter,} ) =>
{
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={ 400 } height={ 400 }>
                <Pie
                    dataKey="y"
                    data={ data }
                    cx="50%"
                    cy="50%"
                    nameKey={ nameKey }
                    outerRadius={ radius }
                >
                    { data.map( ( entry, index ) => (
                        <Cell key={ `cell-${ index }` }
                            fill={ entry.fill } />
                    ) ) }
                </Pie>
                <Tooltip formatter={ toolTipFormat } />
            </PieChart>
        </ResponsiveContainer>

    );
};

const PieKeys = (
    {
        data = defaults.data
    } ) =>
{
    return data.map( val =>
    (
        <div key={ val.x } className={ styles.key }>
            <div style={ { backgroundColor: val.fill } } className={ styles.keyColor } />
            <p>{ val.x }</p>
        </div>
    ) );

};

export { MyPie, PieKeys };
