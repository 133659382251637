import { api } from '../../utils/api'
import { rootwalletConstants } from '../constants';
import { dispatcher } from './utils'

const { GETALL_REQUEST, GETALL_SUCCESS, GETALL_FAILURE, RESET_ROOTWALLET } =
  rootwalletConstants;

const getAll = () => async dispatch => {
    dispatch( dispatcher( GETALL_REQUEST ) )

    const data = await api.get(
      'dashboard/masterWallet/balance',
      {},
      { useHeaders: true, useQuery: true },
    );

    if (data.error) {
        dispatch( dispatcher( GETALL_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( GETALL_SUCCESS, data ) )
    }
}


const reset = () => async dispatch => {
    dispatch(dispatcher(RESET_ROOTWALLET));
}

export const rootwalletActions =
{
    getAll,
    reset,
};