import { merchantTxConstants } from '../constants';

import { request, failure } from './utils';

const {
  GETALL_REQUEST,
  GETALL_SUCCESS,
  GETALL_FAILURE,
  RESET_TRANSACTIONS,
  PAGE_SIZE,
} = merchantTxConstants;

const initialState = {
  page: 1,
  totalPages: 1,
  size: PAGE_SIZE,
  content: {
    1: [],
  },
  error: null,
  status: 'idle',
  realCount: 0,
  response: '',
};

export const merchantTx = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_REQUEST:
      return request(state);

    case GETALL_FAILURE:
      return failure(state, action.payload);

    case GETALL_SUCCESS:
      return {
        ...state,
        error: null,
        status: 'resolved',
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        size: action.payload.size,
        realCount: action.payload.content.length,
        content: {
          ...state.content,
          [action.payload.page]: [...action.payload.content],
        },
      };

    case RESET_TRANSACTIONS:
      return initialState;

    default:
      return state;
  }
};
