import React from 'react';
import styles from './NoData.module.scss';

export const NoData = ( { message } ) => 
{
    return (
        <div className={ styles.message }>
            <h1>{ message || "No Data Available" }</h1>
        </div>
    );
};
