import React from 'react';
import classNames from "classnames";

import styles from './Loader.module.scss';


const Loader = ( { classes } ) =>
{
    return (
        <div className={ classNames( styles.loader, classes ) }>Loading...</div>
    );
};

export { Loader };
