import { tradesConstant } from "../constants";

import {
  request,
  failure
} from './utils'

const {
  RESET_TRANSACTIONS,
  GETALL_REQUEST_ON_HOLD,
  GETALL_FAILURE_ON_HOLD,
  GETALL_SUCCESS_ON_HOLD,
  GETALL_FAILED,
  GETALL_SUCCESS,
} = tradesConstant;

const initialState = {
  page: 1,
  totalPages: 1,
  size: 35,
  content: {
    1: [],
  },
  error: null,
  status: 'idle',
  realCount: 0,
  response: '',
  message: ''
};

export const trade = ( state = initialState, action ) => {
  switch (action.type) {
    case GETALL_REQUEST_ON_HOLD:
      return request(state);

    case GETALL_FAILURE_ON_HOLD:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 'rejected',
      };
    case GETALL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 'resolved',
        response: action.payload,
      };

    case GETALL_SUCCESS_ON_HOLD:
      return {
        ...state,
        error: null,
        status: 'resolved',
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        size: action.payload.size,
        realCount: action.payload.content.length,
        content: {
          ...state.content,
          [action.payload.page]: [...action.payload.content],
        },
      };

    case RESET_TRANSACTIONS:
      return initialState;

    default:
      return state;
  }
}