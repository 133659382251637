import React from 'react';
import styles from './Retry.module.scss';

const Retry = ( { message, callback } ) =>
{
    return (
        <div className={ styles.error }>
            <p>{ message || "Unable to complete request" }</p>
            <button onClick={ callback }>Retry</button>
        </div>
    );
};

export { Retry };
