const initialState = {
    darkMode: true
}

export const theme = ( state = initialState, action) => {
    switch (action.type) {
        case "TOGGLE":
            return {
                darkMode: !state.darkMode
            }
    
        default:
            return state;
    }
}