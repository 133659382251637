import { currencyConstants } from '../constants'

import {
  request,
  failure,
  getAllSuccess
} from './utils'

const {
  GETALL_REQUEST,
  GETALL_SUCCESS,
  GETALL_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  CREATE_FEE_REQUEST,
  CREATE_FEE_SUCCESS,
  CREATE_FEE_FAILURE,
  UPDATE_FEE_REQUEST,
  UPDATE_FEE_SUCCESS,
  UPDATE_FEE_FAILURE,
  DELETE_FEE_REQUEST,
  DELETE_FEE_SUCCESS,
  DELETE_FEE_FAILURE,
  RESET_CURRENCIES,
  RESET_FEE_STATUS
} = currencyConstants

const initialState = {
  totalPages: 1,
  totalElements: 0,
  size: 20,
  content: [],
  error: null,
  status: "idle",
  fee: {
    status: "idle",
    error: null,
  },
  updated: false,
}

export const currencies = ( state = initialState, action) => {
  switch(action.type) {
    case GETALL_REQUEST:
      return request(state);
    
    case GETALL_FAILURE:
      return failure(state, action.payload)
    
    case GETALL_SUCCESS:
      return getAllSuccess(state, action.payload)
    
    case CREATE_REQUEST:
      return request(state);
    
    case CREATE_FAILURE:
      return failure(state, action.payload)

    case CREATE_SUCCESS:
      return { ...state, updated: true, status: 'resolved' }
    
    case UPDATE_REQUEST:
      return request(state);

    case UPDATE_FAILURE:
      return failure( state, action.payload )

    case UPDATE_SUCCESS:
      return {
        ...state,
        content: state.content.map((currency) => currency.id === action.payload.id ? action.payload : currency ),
        status: 'resolved',
        updated: true
      }
    
    case CREATE_FEE_REQUEST: 
      return { ...state, fee: { status: 'pending'} }

    case CREATE_FEE_FAILURE:
      return { ...state, fee: { status: 'rejected', error: action.payload } }
    
    case CREATE_FEE_SUCCESS:
      return { ...state, updated: true, fee: { status: 'resolved', error: null } }
    
    case UPDATE_FEE_REQUEST: 
      return  { ...state, fee: { status: 'pending'} }
    
    case UPDATE_FEE_FAILURE:
      return { ...state, fee: { status: 'rejected', error: action.payload } }

    case UPDATE_FEE_SUCCESS:
      return {
        ...state,
        fee: { status: 'resolved'},
        content: state.content.map(
          (currency) => 
            currency.id === action.payload.txCurrencyId ? 
              {
                ...currency, 
                txFees: currency.txFees.map((fee) => fee.id === action.payload.id ? action.payload : fee)
              } 
              : currency)
      }
    
    case DELETE_FEE_REQUEST:
      return  { ...state, fee: { status: 'pending'} }
    
    case DELETE_FEE_FAILURE:
      return { ...state, fee: { status: 'rejected', error: action.payload } }

    case DELETE_FEE_SUCCESS:
      return {
        ...state,
        fee: { status: 'resolved'},
        content: state.content.map(
          (currency) => 
            currency.id === action.payload.txCurrencyId ? 
              { 
                ...currency, 
                txFees: currency.txFees.filter((fee) => fee.id !== action.payload.id )
              } 
              : currency)
      }

    case RESET_CURRENCIES:
      return initialState;
    
    case RESET_FEE_STATUS:
      return { ...state, fee: { status: 'resolved', error: null } }
      
    default: 
      return state;
    
  }
}