import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router } from "react-router-dom";
import './index.scss';
import { store } from "./redux";
import theme from './assets/theme'
import App from './App';
import reportWebVitals from './reportWebVitals';
//render
ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <Router>
        <ChakraProvider theme = {theme}>
          <App />
        </ChakraProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById( 'root' )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
