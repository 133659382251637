const request = ( state ) => (
    {
        ...state,
        error: null,
        loading: true,
        status: "pending"
    }

);

const failure = ( state, error ) => (
    {
        ...state,
        loading: false,
        error: error,
        status: "rejected"
    }

);


const getAllSuccess = ( state, data ) =>
{

    let totalElements = data.length;
    let totalPages = data.length % state.size === 0 ? data.length / state.size : Math.floor(data.length / state.size) + 1;

    return({
        ...state,
        totalElements,
        totalPages,
        status: 'resolved',
        updated: false,
        content: [...data]
    })
    

};


// const getAllSuccess = ( state, data ) =>
// {

//     if ( data.first )
//     {

//         return {
//             ...state,
//             loading: false,
//             error: null,
//             ...data,
//             status: "resolved",
//             realCount: data.content.length
//         };


//     }
//     else
//     {
//         const { first, last, number } = data;
//         const size = 24;
//         const content = [ ...state.content ];
//         const start = number * size;
//         const end = start + size;
//         let realCount = state.realCount;

//         if ( !content[ start ] )
//         {

//             for ( let i = start; i < end; i++ ) 
//             {
//                 if ( data.content[ i - start ] )
//                 {
//                     realCount++;
//                     content[ i ] = data.content[ i - start ];
//                 }
//             }
//         }


//         return {
//             ...state,
//             loading: false,
//             error: null,
//             first, last, number,
//             content,
//             status: "resolved",
//             realCount,
//         };

//     }


// };


export 
{
    request,
    failure,
    getAllSuccess
};
