import { api } from '../../utils/api';
import { rolesConstants, defaultPermissions } from '../constants';
import { dispatcher } from './utils';

const {
  GETALL_REQUEST,
  GETALL_SUCCESS,
  GETALL_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  RESET_ROLES,
  GETALL_PERMISSIONS,
} = rolesConstants;

const getAll = () => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.get('admin/roles', {}, { useHeaders: true });

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};

const getAllPermissions = () => async (dispatch) => {
  const data = await api.get(
    'admin/roles/permissions',
    {},
    { useHeaders: true },
  );

  if (data.error) {
    const payload = {
      data: defaultPermissions,
      fromAPI: false,
    };
    dispatch(dispatcher(GETALL_PERMISSIONS, payload));
  } else {
    const payload = {
      data: data,
      fromAPI: true,
    };
    dispatch(dispatcher(GETALL_PERMISSIONS, payload));
  }
};

const createRole = (role) => async (dispatch) => {
  dispatch(dispatcher(CREATE_REQUEST));

  const data = await api.post('admin/roles', role, { useHeaders: true });

  if (data.error) {
    dispatch(dispatcher(CREATE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(CREATE_SUCCESS, data));
  }
};

const editRole = (role) => async (dispatch) => {
  dispatch(dispatcher(UPDATE_REQUEST));

  const data = await api.put(`admin/roles/${role.id}`, role, {
    useHeaders: true,
  });

  if (data.error) {
    dispatch(dispatcher(UPDATE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(UPDATE_SUCCESS, role));
  }
};

const deleteRole = (id) => async (dispatch) => {
  dispatch(dispatcher(DELETE_REQUEST));

  const data = await api.del(`admin/roles/${id}`, {}, { useHeaders: true });

  if (data.error) {
    dispatch(dispatcher(DELETE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(DELETE_SUCCESS, { id }));
  }
};

const reset = () => async (dispatch) => {
  dispatch(dispatcher(RESET_ROLES));
};

export const rolesActions = {
  getAll,
  createRole,
  editRole,
  deleteRole,
  reset,
  getAllPermissions,
};
