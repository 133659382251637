import React, { useState } from 'react';
import classNames from 'classnames';

import 
{
    BiChevronsLeft,
    BiChevronsRight,
    BiChevronLeft,
    BiChevronRight
}
    from "react-icons/bi";

// import styles from './Table.module.scss';
import { useSelector } from 'react-redux';


const TableExt = ( {
    size,
    total,
    totalPages,
    currentPage,
    goTo,
    goNext,
    goBack
} ) => 
{
    const { darkMode } = useSelector ( state => state.theme )
    const styles = darkMode ? require("./TableDark.module.scss") : require("./Table.module.scss")

    const [ page, setPage ] = useState( 1 );

    const changeHandler = e =>
    {
        let { value } = e.target;
        value = parseInt( value );

        if ( +value && +value <= totalPages )
        {
            setPage( value );
            goTo( value );
        }
        else
        {
            setPage( "" );
        }
    };

    const getIndexOfFirstElementOnPage = () => {
        const index = (currentPage + 1) * size - (size - 1)
        return index
    }

    const getIndexOfLastElementOnPage = () => {
        if(currentPage+1 === totalPages){
            return total
        }
        return getIndexOfFirstElementOnPage() + size - 1
    }

    return (
        <section className={ styles.tableExts }>
            {/* <p> { size } out of { total } items </p> */}
            <p> { getIndexOfFirstElementOnPage() } - { getIndexOfLastElementOnPage() } out of { total } items </p>
            <div className={ styles.btns }>

                <button
                    onClick={ () => goTo( 1 ) }
                    className={ classNames( styles.control,
                        { [ styles.active ]: currentPage === 0 } ) }>
                    <BiChevronsLeft />
                </button>
                <button
                    onClick={ goBack }
                    className={ classNames( styles.control,
                        { [ styles.active ]: currentPage === 0 } ) }>
                    <BiChevronLeft />
                </button>

                <p className={ styles.indic }>
                    Page { currentPage + 1 } of { totalPages }
                </p>


                <div className={ styles.jump }>
                    <p>Go to page </p>
                    <input value={ page } onChange={ changeHandler } type="number" />
                </div>

                <button
                    onClick={ goNext }
                    className={ classNames( styles.control,
                        { [ styles.active ]: currentPage === totalPages - 1 } ) }>
                    <BiChevronRight />
                </button>
                <button
                    onClick={ () => goTo( totalPages ) }
                    className={ classNames( styles.control,
                        { [ styles.active ]: currentPage === totalPages - 1 } ) }>
                    <BiChevronsRight />
                </button>
            </div>
        </section>
    );
};

export { TableExt };
