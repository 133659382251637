import React from 'react';
import 
{
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

/* SHAPE OF series */
/*

const series = [
  {
    name: 'Series 1',
    data: [],
    stroke:"#xxxxxx"
  },
  {
    name: 'Series 2',
    data: [],
  },...
];

*/

const MyLineChart = (
    {
        series,
        xKey,
        yKey,
        xType,
        yType,
        xFormat,
        yFormat,
        type,
        yDomain,
        xDomain,
        legendPos = "top"
    } ) =>
{
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart width={ 500 } height={ 300 }>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={ xKey }
                    type={ xType || "category" }
                    allowDuplicatedCategory={ false }
                    domain={ xDomain }
                    tickFormatter={ xFormat } />
                <YAxis dataKey={ yKey }
                    type={ yType || "category" }
                    allowDuplicatedCategory={ false }
                    tickFormatter={ yFormat }
                    domain={ yDomain }
                />
                <Tooltip />
                <Legend verticalAlign={ legendPos } height={ 30 } />
                { series.map( ( s ) => (
                    <Line
                        type={ type || "monotone" }
                        dataKey={ yKey }
                        data={ s.data }
                        name={ s.name }
                        stroke={ s.stroke }
                        animationDuration={ s.duration || 600 }
                        key={ s.name } />
                ) ) }


            </LineChart>
        </ResponsiveContainer>
    );
};

export { MyLineChart };
