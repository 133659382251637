import { storeFrontCategoryConstant } from '../constants'

import {
  request,
  failure,
  getAllSuccess
} from './utils'

const {
  GETALL_REQUEST,
  GETALL_SUCCESS,
  GETALL_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  RESET_TRANSACTIONS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS
} = storeFrontCategoryConstant

const initialState = {
  totalPages: 1,
  totalElements: 0,
  size: 20,
  content: [],
  error: null,
  status: "idle",
  updated: false,
}

export const storefrontCategories = ( state = initialState, action) => {
  switch(action.type) {
    case GETALL_REQUEST:
      return request(state);
    
    case GETALL_FAILURE:
      return failure(state, action.payload)
    
    case GETALL_SUCCESS:
      return getAllSuccess(state, action.payload.content)

    
    case CREATE_REQUEST:
      return request(state);
    
    case CREATE_FAILURE:
      return failure(state, action.payload)

    case CREATE_SUCCESS:
      return { ...state, updated: true, status: 'resolved' }
    
    case UPDATE_REQUEST:
      return request(state);

    case UPDATE_FAILURE:
      return failure( state, action.payload )

    case UPDATE_SUCCESS:
      return {
        ...state,
        content: state.content.map((currency) => currency.id === action.payload.id ? action.payload : currency ),
        status: 'resolved',
        updated: true
      }

    case DELETE_REQUEST:
      return request(state);

    case DELETE_FAILURE:
      return failure(state);

    case DELETE_SUCCESS:
      return {
        ...state,
        loading: 'false',
        content: state.content.filter(ref => ref.id !== action.payload.id),
        status: 'resolved',
        error: 'null'
      }

    case RESET_TRANSACTIONS:
      return initialState;
      
    default: 
      return state;
    
  }
}