import React from 'react';
import styles from './PageTitle.module.scss';


function PageTitle ( { text, children } )
{
    return (
        <div className={ styles.flex }>
            <h1 className={ styles.title }>
                { text }
            </h1>
            { children }
        </div>

    );
}

export { PageTitle };
