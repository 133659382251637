import {customerConstants} from '../constants'

import {
    request,
    failure,
    getAllSuccess
} from './utils'

const {
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    CUSTOMER_RESET
} = customerConstants

const initialState = {
    totalPages: 0,
    totalElements: 0,
    first: true,
    last: false,
    number: 0,
    size: 24,
    content: [],
    error: null,
    status: "idle",
    realCount: 0
}

export const customers = ( state = initialState, action) => {
    switch (action.type) {
        case GETALL_REQUEST:
            return request(state)

        case GETALL_SUCCESS:
            return getAllSuccess(state, action.payload)

        case GETALL_FAILURE:
            return failure(state, action.payload)

        case CUSTOMER_RESET:
            return initialState
    
        default:
            return state;
    }
}