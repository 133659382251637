export const currencyRatesConstant = {
  GETALL_REQUEST: 'CURRENCY_RATES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CURRENCY_RATES_GETALL_SUCCESS',
  GETALL_FAILURE: 'CURRENCY_RATES_GETALL_FAILURE',

  CREATE_REQUEST: 'CURRENCY_RATES_CREATE_REQUEST',
  CREATE_SUCCESS: 'CURRENCY_RATES_CREATE_SUCCESS',
  CREATE_FAILURE: 'CURRENCY_RATES_CREATE_FAILURE',
  
  UPDATE_REQUEST: 'CURRENCY_RATES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CURRENCY_RATES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CURRENCY_RATES_UPDATE_FAILURE',

  DELETE_REQUEST: 'CURRENCY_RATES_DELETE_REQUEST',
  DELETE_SUCCESS: 'CURRENCY_RATES_DELETE_SUCCESS',
  DELETE_FAILURE: 'CURRENCY_RATES_DELETE_FAILURE',

  RESET_CURRENCY_RATES: "RESET_CURRENCY_RATES"
}