import { rolesConstants } from '../constants/roles.constant'

import {
    request,
    failure,
    getAllSuccess
} from './utils'

const {
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    CREATE_REQUEST,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILURE,
    RESET_ROLES,
    GETALL_PERMISSIONS,
} = rolesConstants

const initialState = {
    totalPages: 1,
    totalElements: 0,
    size: 12,
    content: [],
    permissions: {
        content: [],
        fetchedFromAPI: false,
    },
    error: null,
    status: "idle",
    updated: false,
}

export const roles = ( state = initialState, action ) => {
    switch (action.type) {
        case GETALL_REQUEST:
            return request(state);        
        
        case GETALL_FAILURE:
            return failure( state, action.payload )

        case GETALL_SUCCESS:
            return getAllSuccess( state, action.payload )
            
        case CREATE_REQUEST:
            return request(state);

        case CREATE_FAILURE:
            return failure( state, action.payload )

        case CREATE_SUCCESS:
            return { ...state, updated: true, status: 'resolved' }

        case UPDATE_REQUEST:
            return request(state);

        case UPDATE_FAILURE:
            return failure( state, action.payload )

        case UPDATE_SUCCESS:
            return {
                ...state,
                content: state.content.map((role) => role.id === action.payload.id ? action.payload : role ),
                updated: true,
                status: 'resolved'
            }

        case DELETE_REQUEST:
            return request({...state, deleteRequest: 'pending'});

        case DELETE_FAILURE:
            return failure( {
                ...state,
                deleteRequest: 'rejected'
            }, action.payload )

        case DELETE_SUCCESS:
            return getAllSuccess( {
                ...state,
                deleteRequest: 'successful'
            }, [ ...state.content.filter( role => role.id !== action.payload.id) ])
            // map the state to show the deleted role instead of loading from the api again

        case RESET_ROLES:
            return initialState
        
        case GETALL_PERMISSIONS:
            return ({
                ...state,
                permissions: {
                    content: action.payload.data,
                    fetchedFromAPI: action.payload.fromAPI
                }
            })

        default:
            return state;
    }
}