import { api } from '../../utils/api'
import { adminUserConstants } from '../constants'
import { dispatcher } from './utils'

const {
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    RESET_ADMIN_USERS
} = adminUserConstants

const getAll = () => async dispatch => {
    dispatch( dispatcher( GETALL_REQUEST ) )

    const data = await api.get( 'admin' , { }, { useHeaders: true, useQuery: true} )

    if (data.error) {
        dispatch( dispatcher( GETALL_FAILURE, data.error ) )
    } else {
        dispatch( dispatcher( GETALL_SUCCESS, data ) )
    }
}

const reset = () => async dispatch => {
    dispatch( dispatcher( RESET_ADMIN_USERS ) )
}

export const adminUserActions =
{
    getAll,
    reset,
};