import { referralCommissionConstants } from '../constants'

import {
    request,
    failure
} from './utils'

const {
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    CREATE_REQUEST,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILURE,
    RESET_REFERRAL_COMMISSION,
} = referralCommissionConstants

const initialState = {
  content: [],
  error: null,
  status: "idle"
}

export const referralConfig = ( state = initialState, action ) => {
    switch (action.type) {
        case GETALL_REQUEST:
            return request(state);        
        
        case GETALL_FAILURE:
            return failure( state, action.payload )

        case GETALL_SUCCESS:
            return {
                ...state,
                error: null,
                status: 'resolved',
                loading: 'false',
                size: action.payload.size,
                content: [ ...action.payload ]
            }
            
        case CREATE_REQUEST:
            return request(state);

        case CREATE_FAILURE:
            return failure( state, action.payload )

        case CREATE_SUCCESS:
            return {
              ...state,
              error: null,
              loading: 'false',
              status: 'resolved',
              content: [ ...state.content, action.payload ]
            }

        case UPDATE_REQUEST:
            return request(state);

        case UPDATE_FAILURE:
            return failure( state, action.payload )

        case UPDATE_SUCCESS:
            return {
              ...state,
              error: null,
              loading: 'false',
              status: 'resolved',
              content: state.content.map( ref => {
                if(ref.id === action.payload.id){
                  return action.payload
                } 
                else return ref 
              })
            }

        case DELETE_REQUEST:
            return request(state);

        case DELETE_FAILURE:
            return failure(state);

        case DELETE_SUCCESS:
            return {
                ...state,
                loading: 'false',
                content: state.content.filter( ref => ref.id !== action.payload.id),
                status: 'resolved',
                error: 'null'
            }
            // map the state to show the deleted user instead of loading from the api again

        case RESET_REFERRAL_COMMISSION:
            return initialState

        default:
            return state;
    }
}