import { api } from "../../utils/api";
import { linkReqConstants } from '../constants';
import { dispatcher } from "./utils";

const getAll = ( page = 1 ) => async dispatch =>
{
    dispatch( dispatcher( linkReqConstants.GETALL_REQUEST ) );

    const data = await api.get( "linkrequest", { page } );

    if ( data.error )
    {
        dispatch( dispatcher( linkReqConstants.GETALL_FAILURE, data.error ) );
    }
    else
    {
        dispatch( dispatcher( linkReqConstants.GETALL_SUCCESS, data ) );
    }
};

const reset = () => async dispatch =>
{
    dispatch( dispatcher( linkReqConstants.LINK_RESET ) );
};

export const linkReqActions =
{
    getAll,
    reset
};
