import { externalWalletConstants } from '../constants';

import {
    request,
    failure
} from './utils'

const { GETALL_REQUEST, GETALL_SUCCESS, GETALL_FAILURE, RESET_COUNTRIES } =
  externalWalletConstants;

const initialState = {
  content: [],
  error: null,
  status: "idle",
  loading: true
}

export const externalWalletConfig = ( state = initialState, action ) => {
    switch (action.type) {
        case GETALL_REQUEST:
            return request(state);        
        
        case GETALL_FAILURE:
            return failure( state, action.payload )

        case GETALL_SUCCESS:
            return {
                ...state,
                error: null,
                status: 'resolved',
                loading: 'false',
                size: 1,
                content: action.payload
            }
            
            // map the state to show the deleted user instead of loading from the api again

        case RESET_COUNTRIES:
            return initialState

        default:
            return state;
    }
}