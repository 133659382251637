export const themeColors = {
    red: '#da0a0a',
    yellow: '#FFD13C',
    green: '#33d5ba',
    deepGreen: '#2BB19B',
    lightGreen: '#f2fffd',
    lighterGreen: '#d3efea',
    blue: '#6DC4F2',
    black: '#303030',
    grey: '#7a869a',
    grey2: '#E5E5E5',
    formBackground: '#f6f6f6'
};