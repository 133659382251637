import styles from './Login.module.scss';
import { FaUserAlt } from 'react-icons/fa';
import { BsArrowLeft } from 'react-icons/bs';
import { useState } from 'react';
import { Spinner, Text } from '@chakra-ui/react';
import { api, publicURL } from '../../../utils/api';

export const ForgotPassword = ({goBack}) => {
  const [ email, setEmail ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState('')
  const [ success, setSuccess ] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    setError('')
    const payload = {
      email,
      callbackUrl: `${publicURL}/login/reset-password`
    }
    api.post('admin/auth/forget-password/begin', payload)
      .then( response => {
        setLoading(false)
        if(response.error){
          setError('This account does not exist')
          console.log(response.error)
        }
        else {
          setSuccess(true)
        }
      })
  }

  return(
      <div className={ styles.formBox }>
          <h3>Forgot Password</h3>
          <section className={ styles.formWrap }>
              <BsArrowLeft onClick={() => goBack()} className={styles['back-button']}/>
              {
                success ?
                <Text fontSize={'17px'} textAlign={'center'} marginTop={'85px'}>
                  Please check your email. Password reset link has been sent to you.
                </Text> 
                :
                <>
                  <p className={styles["forgot-password-text"]}>Password reset link will be sent to your email address.</p>
                  <div className={ styles.formRow }>
                      <label htmlFor='username'>
                          <FaUserAlt />
                      </label>
                      <input
                          type='text'
                          name='email'
                          placeholder='email'
                          required
                          value={ email }
                          onChange={ e => setEmail(e.target.value)}
                      />
                      <button
                          className={ styles.submit }
                          disabled={ loading || !email }
                          onClick={ handleSubmit }
                      >
                        {
                          loading ? 
                            <Spinner /> :
                            error ?
                            'Retry' :
                            'Submit' 
                        }
                      </button>
                  </div>
                </>
              }
              
              {
                error && 
                <p className={styles['error-text']}>{error}</p>
              }
          </section>
      </div>
  )
}