// theme/index.js
import { extendTheme } from '@chakra-ui/react'

// Global style overrides
import styles from './styles'

// Foundational style overrides
import typography from './typography'

// Component style overrides
import * as Components from './components/'

const overrides = {
  styles,
  ...typography,
  // Other foundational style overrides go here

  components: {
    ...Components
  },
}

export default extendTheme(overrides)