import React from 'react';
import { getObjProp } from "../../utils/helpers";
// import styles from './Table.module.scss';
import { Tr, Td } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

function TableRow ( { rowData, columns, ind, history, rowClickHandler } )
{
    const { darkMode } = useSelector ( state => state.theme )
    const styles = darkMode ? require("./TableDark.module.scss") : require("./Table.module.scss")

    const render = ( row, index ) =>
    {
        return columns[ index ].render( getObjProp( columns[ index ].key, rowData ), row, index, history );
    };

    return (
        <Tr onClick={ () => rowClickHandler( rowData ) } >
            <Td variant={'small'} className={styles.row}>
                { ind + 1 }
            </Td>
            {
                columns.map( ( _, index ) => (
                    <Td key={ index } title={ typeof(render( rowData, index )) === 'string' ? render( rowData, index ): null}  variant={'small'} className={styles.row}>
                        { render( rowData, index ) }
                    </Td>

                ) )
            }
        </Tr>
    );
}

export default TableRow;
