export const Heading = {
    baseStyle: {
        //generic style goes here
    },
    variants: {
        centered: {
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '60px'
          }
    }
}