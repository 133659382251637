import { Box, Grid, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Loader } from "../../../components/Loader/Loader";
import { formatCurrency } from "../../../utils/helpers";
import styles from '../../../pages/Dashboard/Users/Users.module.scss';

const sortImages = (currencyData) => {
  let db = {};
  for (let currency of currencyData) {
    db[currency.name] = currency.imgUrl;
  }
  return db;
};

const CurrencyCard = ({ item, currencyImages, fiat = false, dark = false }) => {
  return (
    <Box className={styles['currency-card']}>
      <img
        className={styles['currency-image']}
        src={fiat === true ? currencyImages[`VC_${item.currency}`] : currencyImages[item.currency]}
        alt='' />
      <Text color={dark ? 'blackAlpha.800' : 'inherit'} className={styles['currency-name']} >{item.currency}</Text>
      <Text color={dark ? 'blackAlpha.800' : 'inherit'} className={styles['currency-amount']}>{formatCurrency(item.balance)}</Text>
    </Box>
  );
};

export const Wallet = ({ fiat, crypto, currency, currencyStatus }) => {
  const { darkMode } = useSelector(state => state.theme);
  const currencyImages = sortImages(currency);
  return (
    <Box>
      {
        ((currencyStatus === 'resolved') || (currencyStatus === 'rejected')) &&
        <>
          <SimpleGrid w={{ sm: "80vw", md: "90vw" }} columns={{ sm: 1, md: 2, xl: 3 }} spacing={10}>
            {
              fiat.map((item, index) => (
                <GridItem>
                  <CurrencyCard item={item} key={index} currencyImages={currencyImages} fiat={true} dark={darkMode} />
                </GridItem>

              ))
            }
            {
              crypto.map((item, index) => (
                <GridItem>
                  <CurrencyCard item={item} key={index} currencyImages={currencyImages} dark={darkMode} />
                </GridItem>

              ))
            }
          </SimpleGrid>
        </>
      }
      {
        currencyStatus === 'pending' &&
        <>
          <Loader />
        </>
      }
    </Box>
  );
};