import { userConstants } from '../constants'

import {
    request,
    failure,
    getAllSuccess
} from './utils'

const {
    PAGE_SIZE,
    GETALL_REQUEST,
    GETALL_SUCCESS,
    GETALL_FAILURE,
    CREATE_REQUEST,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILURE,
    RESET_USERS,
} = userConstants

const initialState = {
    page: 1,
  totalPages: 1,
  size: PAGE_SIZE,
  content: { 
    1: []
  },
  error: null,
  status: "idle",
  realCount: 0
}

export const users = ( state = initialState, action ) => {
    switch (action.type) {
        case GETALL_REQUEST:
            return request(state);        
        
        case GETALL_FAILURE:
            return failure( state, action.payload )

        case GETALL_SUCCESS:
            return {
                ...state,
                error: null,
                status: 'resolved',
                page: action.payload.page,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements,
                size: action.payload.size,
                realCount: action.payload.content.length,
                content: { 
                  ...state.content, 
                  [action.payload.page]: [ ...action.payload.content ], 
                }
            }
            
        case CREATE_REQUEST:
            return request(state);

        case CREATE_FAILURE:
            return failure( state, action.payload )

        case CREATE_SUCCESS:
            return getAllSuccess( {
                ...state,
                content: [ action.payload, ...state.content ]
            },
            action.payload )

        case UPDATE_REQUEST:
            return request(state);

        case UPDATE_FAILURE:
            return failure( state, action.payload )

        case UPDATE_SUCCESS:
            return getAllSuccess( {
                ...state,
                content: state.content.map( user => user.id === action.payload.id ? action.payload : user)
            }, 
            action.payload )

        case DELETE_REQUEST:
            return request({...state, deleteRequest: 'pending'});

        case DELETE_FAILURE:
            return failure( {
                ...state,
                deleteRequest: 'rejected'
            }, action.payload )

        case DELETE_SUCCESS:
            return getAllSuccess( {
                ...state,
                content: state.content.filter( user => user.id !== action.payload.id),
                deleteRequest: 'successful'
            },
            action.payload.data )
            // map the state to show the deleted user instead of loading from the api again

        case RESET_USERS:
            return initialState

        default:
            return state;
    }
}