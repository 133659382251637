import { extendTheme } from '@chakra-ui/react'

// Global style
const style = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: 'gray.400',
        color: 'white',
      },
      // styles for the `a`
      a: {
        color: 'teal.500',
        _hover: {
          textDecoration: 'underline',
        },
      },
      html: {
        fontSize: '15px'
      }
    },
  },
})

export default style;
