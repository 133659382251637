import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  InputGroup,
  InputRightElement,
  Spinner,
  Text
} from '@chakra-ui/react'
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
import { string as yupString } from 'yup';
import { authActions } from '../../redux/actions';
import { api } from '../../utils/api';
import styles from './Header.module.scss'


export function ChangePassword({isOpen, onClose}){
  const initialValues = {
    oldPassword: {
      value: '',
      touched: false,
      error: "required"
    },
    newPassword: {
      value: '',
      touched: false,
      error: "required"
    },
    confirmPassword: {
      value: '',
      touched: false,
      error: "required"
    },
  }

  const dispatch = useDispatch()
  const [ showOldPassword, setShowOldPassword ] = useState(false)
  const [ showNewPassword, setShowNewPassword ] = useState(false)
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)
  const [ dto, setDto] = useState(initialValues)
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState('')
  const [ success, setSuccess ] = useState(false)

  const { oldPassword, newPassword, confirmPassword } = dto

  const validationSchema = {
    oldPassword: yupString()
      .required()
      .required("Old password is required"),
    newPassword: yupString()
      .required()
      .required("New password is required")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/, 'Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character'),

    confirmPassword: yupString()
      .required()
      .required("Must confirm new password")
      .matches(newPassword.value, "Doesn't match new password")
  }

  const validate = (name, value) => {
    let error = "";
    try {
          value = validationSchema[name]?.validateSync(value);
    } catch (err) {
        if (err.name === "ValidationError") {
        error = err.errors.join(",");
        }
    }
    return { value, error };
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const { value: val, error } = validate(name, value)
    setDto(() => ({
        ...dto,
        [name]: { value: val, touched: true, error },
    }));
  }

  const handleSubmit = () => {
    setError(false)
    setLoading(true)
    const payload = {
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
      confirmPassword: confirmPassword.value
    }
    api.post('admin/auth/change-password', payload)
      .then( response => {
        setLoading(false)
        if(response.error){
          setError(response.error)
        }
        else {
          setSuccess(true)
          setTimeout(() => {
            dispatch(authActions.logout())
          }, 4000);
        }
      })
  }

  const isInvalid = () => {
    return oldPassword.error || newPassword.error || confirmPassword.error
  }

  /* useEffect(() => {
    if(newPassword.value !== confirmPassword.value){
      setDto(() => ({
        ...dto,
        confirmPassword: {
          ...dto.confirmPassword,
          error: "Doesn't match new password"
        }
      }))
    }
    else {
      setDto(() => ({
        ...dto,
        confirmPassword: {
          ...dto.confirmPassword,
          error: ""
        }
      }))
    }
  }, [dto, confirmPassword.value, newPassword.value]) */
  
  return(
    <Drawer isOpen={isOpen} placement={'right'} onClose={onClose} >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Change Your Password</DrawerHeader>

        <DrawerBody>

          <FormControl isInvalid={oldPassword.error} isRequired marginTop={'55px'}>
            <FormLabel>Old Password</FormLabel>
            <InputGroup>
              <Input
                type={showOldPassword ? 'text' : 'password'}
                name='oldPassword'
                onChange={handleChange}
              />
              <InputRightElement>
                <Box cursor={'pointer'} onClick={() => setShowOldPassword(e => !e)}>
                  {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                </Box>
              </InputRightElement>
            </InputGroup>
            {
              oldPassword.touched && oldPassword.error &&
              <small className={styles.error}>{oldPassword.error}</small>
            }
          </FormControl>

          <FormControl isInvalid={newPassword.error} isRequired marginTop={'25px'}>
            <FormLabel>New Password</FormLabel>
            <InputGroup>
              <Input
                type={showNewPassword ? 'text' : 'password'}
                name='newPassword'
                onChange={handleChange}
              />
              <InputRightElement>
                <Box cursor={'pointer'} onClick={() => setShowNewPassword(e => !e)}>
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </Box>
              </InputRightElement>
            </InputGroup>
            {
              newPassword.touched && newPassword.error &&
              <small className={styles.error}>{newPassword.error}</small>
            }
          </FormControl>

          <FormControl isInvalid={confirmPassword.error} isRequired marginTop={'25px'}>
            <FormLabel>Confirm New Password</FormLabel>
            <InputGroup>
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                name='confirmPassword'
                onChange={handleChange}
              />
              <InputRightElement>
                <Box cursor={'pointer'} onClick={() => setShowConfirmPassword(e => !e)}>
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </Box>
              </InputRightElement>
            </InputGroup>
            {
              confirmPassword.touched && confirmPassword.error &&
              <small className={styles.error}>{confirmPassword.error}</small>
            }
          </FormControl>

          <Box margin={'50px 0px 30px'}>
            <Button 
              width={'100%'}
              disabled={ loading || success || isInvalid() } 
              margin={'10px'} 
              variant={'solid'} 
              size='sm' 
              colorScheme={'teal'}
              onClick = {handleSubmit}
            >
              {
                error ? 'Retry' :
                loading ? <Spinner /> : 
                success ? 'Success' : 'Submit'
              }
            </Button>
          </Box>

          {
            error && 
            <Text fontSize={'13px'} textAlign={'right'} color={'red'}>{error} </Text>
          }
          
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}