export const Modal = {
    //multicomponent parts
    parts: ['modal', 'modaloverlay', 'modalcontent', 'modalheader', 'modalfooter', 'modalbody', 'modalclosebutton'],
    
    //style that all have in common
    baseStyle: {
      modalcontent: {
        position: 'absolute',
        top: '50%',
        left: '50%'
      },
      modaloverlay: {
        //modaloverlay base style
      },
    },
  };